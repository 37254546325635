// eslint-disable-next-line no-unused-vars
import { Navigate, Outlet } from "react-router-dom";
import Navbar from "../components/navbar/navbar";
import Footer from "../components/footer/footer";

const ProtectedRoute = ({ isAuthenticated }) => {
  return (
    <>
      {isAuthenticated ? (
        <>
          {/* <Navbar /> */}
          <Outlet />
          {/* <Footer /> */}
        </>
      ) : (
        <Navigate to="/login" />
      )}
    </>
  );
};

export default ProtectedRoute;

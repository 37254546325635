/* eslint-disable react-refresh/only-export-components */
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface UploadState {
  isGrowthOpportunityPolling: boolean;
  GrowthOpportunitiesId: string;
  growthOpportunitiesRequestId?: string;
  growthOpportunityTopRankRequestId:string;
  growthOpportunityTopRankDraftRequestId:string;
}

const initialState: UploadState = {
  isGrowthOpportunityPolling:
    localStorage.getItem("isGrowthOpportunityPolling") === "true",
  GrowthOpportunitiesId: localStorage.getItem("GrowthOpportunitiesId") || "",
  growthOpportunitiesRequestId: "",
  growthOpportunityTopRankRequestId:"",
  growthOpportunityTopRankDraftRequestId:""
};

const GrowthOpportunitiesSlice = createSlice({
  name: "growthOpportunitiesSlice",
  initialState,
  reducers: {
    setGrowthOpportunitiesStatus(state, action: PayloadAction<boolean>) {
      state.isGrowthOpportunityPolling = action.payload;
      localStorage.setItem(
        "isGrowthOpportunityPolling",
        action.payload.toString()
      );
    },
    setGrowthOpportunitiesId(state, action: PayloadAction<string>) {
      state.GrowthOpportunitiesId = action.payload;
      localStorage.setItem("GrowthOpportunitiesId", action.payload);
    },
    setGrowthOpportunitiesRequestId(state, action: PayloadAction<string>) {
      state.growthOpportunitiesRequestId = action.payload;
    },
    removeGrowthOpportunities(state) {
      state.isGrowthOpportunityPolling = false;
      state.GrowthOpportunitiesId = "";
      localStorage.removeItem("isGrowthOpportunityPolling");
      localStorage.removeItem("GrowthOpportunitiesId");
    },
    clearGrowthOpportunitiesRequestId(state) {
      state.growthOpportunitiesRequestId = "";
    },
    setGrowthOpportunityTopRankRequestId(state, action: PayloadAction<string>) {
      state.growthOpportunityTopRankRequestId = action.payload;
    },
    setGrowthOpportunityTopRankDraftRequestId(state, action: PayloadAction<string>) {
      state.growthOpportunityTopRankDraftRequestId = action.payload;
    },
  },
});

export const {
  setGrowthOpportunitiesStatus,
  setGrowthOpportunitiesId,
  setGrowthOpportunitiesRequestId,
  removeGrowthOpportunities,
  clearGrowthOpportunitiesRequestId,
  setGrowthOpportunityTopRankRequestId,
  setGrowthOpportunityTopRankDraftRequestId
} = GrowthOpportunitiesSlice.actions;
export default GrowthOpportunitiesSlice.reducer;

import { useContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import useFrostSi8Api from "../../hooks/useFrostSi8Api";
import { FilterContext } from "../../context/FilterProvider";
import styled from "styled-components";
import { PopupBox } from "../../components/table/table.styled";
import { toast } from "react-toastify";
import Button from "../../components/button/button";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { removeSI8PoolingStatus } from "../../store/slices/SI8PoolingStatusSlice";
import { usePostCancelSi8DataRequest } from "../../hooks";

export default function TransformationGeneration() {
  const { handleRequest } = useFrostSi8Api();
  const navigate = useNavigate();
  const [isToastOpen, setIsToastOpen] = useState(false);
  const { setSi8DataResponse, si8DataResponse, showTimer }: any =
    useContext(FilterContext);

  const { mutate: cancelSi8Generation } =
    usePostCancelSi8DataRequest(handleRequest);

  const dispatch = useDispatch();
  const si8PoolingStatus = useSelector(
    (state: RootState) => state.si8PoolingStatusSlice.poolingStatus
  );
  const Si8RequestID = useSelector(
    (state: RootState) => state.si8PoolingStatusSlice.si8RequestID
  );

  const cancelHandler = () => {
    if (isToastOpen) return;

    setIsToastOpen(true);
    const notify = () =>
      toast(cancelPopup(), {
        position: "top-center",
        autoClose: false,
        closeOnClick: false,
        closeButton: false,
        hideProgressBar: true,
        pauseOnHover: false,
        draggable: false,
        theme: "light",
        onClose: () => setIsToastOpen(false),
      });
    notify();
  };
  const cancelPopup = () => (
    <PopupBox>
      <h2>Cancel</h2>
      <p>Are you sure you want to cancel generation?</p>
      <div className="popup-btns">
        <Button
          variant="secondary-outline"
          size="md"
          title="Confirm"
          type="button"
          onClick={() => {
            handleCancelClick();
            toast.dismiss();
            setIsToastOpen(false);
          }}
        />
        <Button
          variant="primary"
          size="md"
          title="Cancel"
          type="button"
          onClick={() => {
            toast.dismiss();
            setIsToastOpen(false);
          }}
        />
      </div>
    </PopupBox>
  );

  const handleCancelClick = () => {
    cancelSi8Generation(Si8RequestID);
    dispatch(removeSI8PoolingStatus());
  };

  return (
    <>
      {si8PoolingStatus !== "idle" ? (
        si8PoolingStatus === "pooling" && showTimer ? (
          <>
            <GeneratingDiv>
              <span className="loading-text">Generating...</span>
              <div className="progress-bar-container">
                <div className="progress-bar"></div>
              </div>
              <button
                className="cancel-button"
                onClick={() => {
                  cancelHandler();
                }}
              >
                Cancel
              </button>
            </GeneratingDiv>
          </>
        ) : (
          <>
            <GeneratingDiv>
              <span className="loading-text">
                {si8PoolingStatus === "completed"
                  ? "Generated"
                  : `Generation Failed : ${si8DataResponse?.message}`}
              </span>
              {si8PoolingStatus === "completed" && Si8RequestID ? (
                <button
                  className="cancel-button view-button"
                  onClick={() => {
                    setSi8DataResponse(null);
                    navigate(
                      `/transformation/strategic-imperative/${Si8RequestID}`
                    );
                    dispatch(removeSI8PoolingStatus());
                  }}
                >
                  View
                </button>
              ) : (
                <button
                  className="cancel-button"
                  onClick={() => {
                    setSi8DataResponse(null);
                  }}
                >
                  Close
                </button>
              )}
            </GeneratingDiv>
          </>
        )
      ) : (
        <></>
      )}
    </>
  );
}

const GeneratingDiv = styled.div`
  padding: 12px;
  margin-bottom: 20px;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 4px 24px;
  .progress-bar-container {
    flex-grow: 1;
    margin: 0 20px;
    height: 8px;
    background-color: #d4d4d4;
    border-radius: 4px;
    overflow: hidden;
    position: relative;
  }

  .progress-bar {
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #0e4c8b, #172d55);
    animation: progress-animation 2s infinite ease-in-out;
  }
  @keyframes progress-animation {
    0% {
      transform: translateX(-100%);
    }

    100% {
      transform: translateX(100%);
    }
  }

  .estimated-time {
    font-size: 14px;
    margin-right: 10px;
  }

  .cancel-button {
    background-color: #f26722;
    color: white;
    border: none;
    border-radius: 4px;
    padding: 6px 12px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .cancel-button:hover {
    background-color: #f7913d;
  }

  .view-button {
    background-color: #00b0f0;
    &:hover {
      background-color: #bcd9ee;
    }
  }
`;

/* eslint-disable @typescript-eslint/no-unused-vars */
import { useQuery, useQueryClient, type UseQueryResult } from "react-query";
import { NotificationService } from "../../services/frost-si8-services";

const useGetAllPopupNotification = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  openCarouselModal: () => void
): UseQueryResult<any | undefined, unknown> => {
  const queryClient = useQueryClient();
  const allPopupNotification = useQuery(
    ["getAllPopupNotification"],
    async () => {
      return await handleRequest(
        NotificationService.getCoreApiApiAppNotificationPopupNotificationForCurrentUser()
      ).then((response: any) => {
        if (response) {
          openCarouselModal();
          return response?.data?.map((item, index: number) => {
            return {
              ...item,
              notificationId: item.id,
              isSeen: item?.isSeen,
              isPopupOpened: !item.isPopupOpened,
            };
          });
        }
      });
    },
    {
      retry: false,
      onError: (error: any) => {
        if (error.response?.status === 503) {
          queryClient.invalidateQueries("getAllPopupNotification");
        }
      },
    }
  );

  return allPopupNotification;
};
export default useGetAllPopupNotification;

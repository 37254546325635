const InitialValuesForm = {
  tableParams: {
    searchKeyword: "",
    sortOrder: "",
    sorting: "",
    skipCount: 0,
    maxResultCount: 10,
  },

  tableParamState: {
    searchKeyword: "",
    sortOrder: undefined,
    fromDate: "",
    toDate: "",
    sorting: "",
    skipCount: 0,
    maxResultCount: 10,
  },
  level1ReportParams: {
    growthOpportunitiesDataRequestId: "",
    growthProcess: "",
    growthPerspective: "",
    company: "",
    programName: "",
    additionalDetails: "",
    searchKeyword: "",
    sortOrder: "",
    sorting: "",
    skipCount: 0,
    maxResultCount: 10,
  },
  savedReportParams: {
    growthOpportunitiesDraftDataRequestId: "",
    growthProcess: "",
    growthPerspective: "",
    company: "",
    programName: "",
    additionalDetails: "",
    searchKeyword: "",
    sortOrder: "",
    sorting: "",
    skipCount: 0,
    maxResultCount: 10,
  },
};

export default InitialValuesForm;

import { useMutation } from "react-query";
import {
  FSi8_Dtos_GrowthOpportunitiesData_GrowthOpportunitiesDataInputDto,
  GrowthOpportunitiesDataRequestService,
} from "../../../services/frost-si8-services";
import { toast } from "react-toastify";
import {
  setGrowthOpportunitiesId,
  setGrowthOpportunitiesStatus,
} from "../../../store/slices/GrowthSlice";
import { useDispatch } from "react-redux";
const useGenerateDataGrowthOpportunity = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): any => {
  const dispatch = useDispatch();
  return useMutation(
    async (
      requestBody?: FSi8_Dtos_GrowthOpportunitiesData_GrowthOpportunitiesDataInputDto
    ) => {
      {
        return await handleRequest(
          GrowthOpportunitiesDataRequestService.postCoreApiApiAppGrowthOpportunitiesDataRequestGrowthOpportunitiesRequest(
            requestBody
          )
        );
      }
    },
    {
      onSuccess: async (response) => {
        if (response != null && response?.data != null) {
          if (response?.success) {
            dispatch(
              setGrowthOpportunitiesId(
                response?.data?.growthOpportunitiesRequestId as string
              )
            );
            dispatch(setGrowthOpportunitiesStatus(true));
          } else {
            toast.error(response?.message);
          }
        }
      },
      onError: async (error) => {
        console.error("Mutation error:", error);
      },
    }
  );
};

export default useGenerateDataGrowthOpportunity;

import { Navigate, Outlet } from "react-router-dom";

interface ISuperAdminRoute {
  user: { role: string } | null;
  isAuthenticated: boolean;
}

export default function SuperAdminRoute({
  user,
  isAuthenticated,
}: ISuperAdminRoute) {
  if (user?.role === undefined) {
    return (
      <div className="grid place-items-center justify-center h-screen text-primary  ">
        Preparing...
      </div>
    );
  }
  if (!user || !isAuthenticated) {
    return <Navigate to="login" replace />;
  }

  if (user.role !== "admin") {
    return <Navigate to="/unauthorized" replace />;
  }

  return <Outlet />;
}

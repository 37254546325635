/* eslint-disable @typescript-eslint/no-unused-vars */
import { Fragment, useCallback, useEffect, useState } from "react";
import { isEqual } from "lodash";
import { TableParamsType } from "../../constants/common";
import { AiGenerateFilterType } from "../../types/common";
import { AiGenerateFilterInitial } from "../../utils/initialValue";
import {
  alphanumericaAndSpecialChar,
  descriptionRegex,
  textWithoutSpace,
} from "../../utils/validation/validations";
import Icon from "../icons";
import { SidebarWrapper } from "./sidebar.styled";
import { useDispatch, useSelector } from "react-redux";
import { setNewGenerated } from "../../store/slices/subSegmentSlice";
import {
  Checkbox,
  Flex,
  Form,
  Input,
  Select,
  Typography,
  Button as Buttons,
  Tag,
  Tooltip,
} from "antd";
import CsInfoTooltip from "../CsInfoTooltip";
import useFrostSi8Api from "../../hooks/useFrostSi8Api";
import useGetGrowthProcessList from "../../hooks/GrowthOpportunity/dropDownList/useGetGrowthProcessList";
import useGetGrowthPerspectiveList from "../../hooks/GrowthOpportunity/dropDownList/useGetGrowthPerspectiveList";
import useGenerateDataGrowthOpportunity from "../../hooks/GrowthOpportunity/PostGrowthopportunityLoader/usePostLoader";
import { useLocation, useNavigate } from "react-router-dom";
import { RootState } from "../../store/store";
import { colors } from "../../constants/themeConstants";
import usePostSi8DataSearchRequest from "../../hooks/post/usePostSi8DataSearchRequest";
import { cn } from "@/lib/utils";
import { Minus, SlidersHorizontal } from "lucide-react";
import { Button } from "../ui/button";
const { Text } = Typography;

const customizeRequiredMark = (
  label: React.ReactNode,
  { required }: { required: boolean }
) => (
  <>
    {label}
    {required && (
      <span style={{ color: colors?.primary, marginLeft: "2px" }}>*</span>
    )}
  </>
);

interface SidbarFilterProps {
  searchParams?: null;
  submitHandler?: () => void;
  generateData?: any;
  sidebarData?: null;
  setSidebarData?: string;
  tableInfo: TableParamsType;
  si8DataResponse?: any;
  handleCancelSi8Data?: any;
  isLoading: boolean;
  setButtonRest?: (value: boolean) => void;
  buttonRest?: boolean;
  filterData: AiGenerateFilterType;
  setFilterData?: React.Dispatch<React.SetStateAction<AiGenerateFilterType>>;
  isActive?: boolean;
  setActive?: any;
  si8RequestId?: string;
  generateDataRef?: any;
}

const Sidebar = ({
  generateData,
  si8DataResponse,
  isLoading,
  setButtonRest,
  buttonRest,
  filterData,
  setFilterData,
  isActive,
  setActive,
  generateDataRef,
}: SidbarFilterProps): JSX.Element => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const { handleRequest } = useFrostSi8Api();

  const { data: growthProcessList, isLoading: isGrowthProcessLoading } =
    useGetGrowthProcessList(handleRequest);

  const { data: growthPerspectiveList, isLoading: isGrowthPerspectiveLoading } =
    useGetGrowthPerspectiveList(handleRequest);

  const { mutate: postSi8, isLoading: postRequestLoading } =
    usePostSi8DataSearchRequest(handleRequest);
  // console.log("growthProcessList", growthProcessList);
  const [generateGrowthOpportunities, setGenerateGrowthOpportunities] =
    useState(false);

  const [selectedValues, setSelectedValues] = useState<string[]>([]);

  const [perspectiveValue, setPerspectiveValue] = useState<string[]>([]);

  const [forSpecificCompany, setForSpecificCompany] = useState(false);

  const [isTooltipOpen, setIsTooltipOpen] = useState(false);
  const [isAdditionalSourcesTooltipOpen, setIsAdditionalSourcesTooltipOpen] =
    useState(false);

  const { mutate, isLoading: isLoadingGenerate } =
    useGenerateDataGrowthOpportunity(handleRequest);

  const isGrowthOpportunityPolling = useSelector(
    (state: RootState) =>
      state.growthOpportunitiesSlice.isGrowthOpportunityPolling
  );

  const si8PoolingStatus = useSelector(
    (state: RootState) => state.si8PoolingStatusSlice.poolingStatus
  );

  const path = useLocation()?.pathname?.split("/")[1];

  useEffect(() => {
    const handleResize = () => {
      window.innerWidth <= 995 ? setActive(true) : setActive(false);
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  // console.log(filterData);

  const onChange = (e) => {
    setGenerateGrowthOpportunities(e.target.checked);
    if (e.target.checked) {
      setForSpecificCompany(false);
      form.setFieldsValue({
        generateCompany: false,
        company: "",
      });
    }
  };

  const specificCompanyChange = (e) => {
    setForSpecificCompany(e.target.checked);
    if (e.target.checked) {
      setGenerateGrowthOpportunities(false);
      form.setFieldsValue({
        generateGrowthOpportunities: false,
      });
    }
  };

  const onFinish = (values) => {
    const updatedFilterData = {
      ...filterData,
      generateGrowthOpportunities: values?.generateGrowthOpportunities ?? false,
      growthInformations: {
        growthPerspective: values?.growthPerspective,
        growthProcess: values?.growthProcess,
        company: values?.company,
        Sector: values?.generateGrowthOpportunities
          ? values?.programArea
          : null,
        additionalDetails: values?.details,
      },
    };
    // setFilterData && setFilterData(updatedFilterData);
    // generateDataRef.current = true;
    // setButtonRest && setButtonRest(true);
    // generateData(updatedFilterData);
    // dispatch(setNewGenerated(true));
    postSi8(updatedFilterData, {
      onSuccess: (resp) => {
        if (resp?.success) {
          setActive(true);
          form.resetFields();
          setGenerateGrowthOpportunities(false);
        }
      },
    });

    if (values?.generateGrowthOpportunities) {
      navigate("growth-opportunity/growth-Table");
    } else {
      navigate("/transformation");
    }
  };

  useEffect(() => {
    const allGrowthProcessValues =
      growthProcessList?.data?.map((item) => item.id) || [];
    const allGrowthPerspectiveValues =
      growthPerspectiveList?.data?.map((item) => item.id) || [];

    setSelectedValues(allGrowthProcessValues);
    setPerspectiveValue(allGrowthPerspectiveValues);

    form.setFieldsValue({
      growthProcess: allGrowthProcessValues,
      growthPerspective: allGrowthPerspectiveValues,
    });
  }, [generateGrowthOpportunities]);

  const handleSelectAll = () => {
    const allValues = growthProcessList?.data?.map((item) => item.id);
    setSelectedValues(allValues as never[]);
    form.setFieldsValue({ growthProcess: allValues });
  };

  const handleSelectAllPerspective = () => {
    const allValues = growthPerspectiveList?.data?.map((item) => item.id);
    setPerspectiveValue(allValues as never[]);
    form.setFieldsValue({ growthPerspective: allValues });
  };

  const handleUnselectAll = () => {
    setSelectedValues([]);
    form.setFieldsValue({ growthProcess: [] });
    form.validateFields(["growthProcess"]);
  };
  const handleUnselectAllPerspective = () => {
    setPerspectiveValue([]);
    form.setFieldsValue({ growthPerspective: [] });
    form.validateFields(["growthPerspective"]);
  };

  const handleChange = (values: any) => {
    setSelectedValues(values);
    form.setFieldsValue({ growthProcess: values });
    setFilterData &&
      setFilterData({
        ...filterData,
        growthInformations: {
          ...filterData.growthInformations,
          growthProcess: values,
        },
      });
  };

  const handleChangePerspective = (values: any) => {
    setPerspectiveValue(values);
    form.setFieldsValue({ growthPerspective: values });
    setFilterData &&
      setFilterData({
        ...filterData,
        growthInformations: {
          ...filterData.growthInformations,
          growthPerspective: values,
        },
      });
  };

  // handle tooltip visibility
  const handleTooltipOpenChange = (open) => {
    if (filterData.keywordsToAvoid === "") {
      setIsTooltipOpen(open);
    } else {
      setIsTooltipOpen(false);
    }
  };

  const handleAdditionalSourcesTooltipOpenChange = (open) => {
    if (filterData.additionalSources === "") {
      setIsAdditionalSourcesTooltipOpen(open);
    } else {
      setIsAdditionalSourcesTooltipOpen(false);
    }
  };

  return (
    <SidebarWrapper className={cn("h-full  relative  w-full md:w-[305px]")}>
      <div className="sidebar relative  ">
        <div className=" px-4 py-[30px] pb-0  ">
          <div className="border-b border-[#20426D] flex items-center justify-between pb-[30px]">
            <div className="flex items-center gap-2 text-white">
              <SlidersHorizontal
                className="text-white cursor-pointer"
                size={20}
                onClick={() => setActive(true)}
              />

              <div className="font-bold text-base">
                {path === "transformation"
                  ? "Transformation Model"
                  : "Growth Opportunities"}
              </div>
            </div>
            <Button variant={"iconButton"} onClick={() => setActive(true)}>
              <Minus className="text-white" />
            </Button>
          </div>
        </div>
        <Form
          form={form}
          layout="vertical"
          initialValues={{ remember: true }}
          onFinish={onFinish}
          requiredMark={customizeRequiredMark}
          disabled={
            si8PoolingStatus == "pooling" ||
            isGrowthOpportunityPolling ||
            postRequestLoading
          }
        >
          <div className="sidebar-body max-h-screen overflow-auto text-white pt-6 ">
            <h1 className="!text-white !text-base mb-2 !capitalize">Search</h1>
            <p className="text-sm mb-4">
              {path === "transformation"
                ? `Explore the top Strategic Imperatives (SI8) that companies must
              leverage to move the organization toward its desired vision and
              culture`
                : `Explore the Top Growth Opportunities (GOs) that companies must leverage to move the organization toward its desired vision and culture`}
            </p>
            <Form.Item
              name="Industry"
              label="Industry"
              rules={[
                { required: true, message: "This is a required field." },
                {
                  pattern: alphanumericaAndSpecialChar,
                  message: "Please enter valid Industry.",
                },
              ]}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Input
                  placeholder="Enter Industry"
                  autoComplete="off"
                  onChange={(e) =>
                    setFilterData &&
                    setFilterData({
                      ...filterData,
                      Industry: e.target.value?.trim(),
                    })
                  }
                />
                <CsInfoTooltip title="A group of businesses that produce or sell similar products or services." />
              </div>
            </Form.Item>
            <Form.Item
              name="Sector"
              label="Sector"
              rules={[
                { required: true, message: "This is a required field." },
                {
                  pattern: alphanumericaAndSpecialChar,
                  message: "Please enter valid Sector.",
                },
              ]}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Input
                  placeholder="Enter Sector"
                  autoComplete="off"
                  onChange={(e) =>
                    setFilterData &&
                    setFilterData({
                      ...filterData,
                      Sector: e.target.value?.trim(),
                    })
                  }
                />
                <CsInfoTooltip title="With in an industry is a more specific subdivision based on a particular product, service, or market segment." />
              </div>
            </Form.Item>
            <Form.Item
              name="SubSector"
              label={
                <>
                  Sub-Sector{" "}
                  <span style={{ color: "#a2a2a2", marginLeft: "5px" }}>
                    (Transformation Journey)
                  </span>
                </>
              }
              rules={[
                { message: "This is a required field." },
                {
                  pattern: alphanumericaAndSpecialChar,
                  message: "Please enter valid Sub-Sector.",
                },
              ]}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Input
                  placeholder="Enter Sub-Sector"
                  autoComplete="off"
                  onChange={(e) =>
                    setFilterData &&
                    setFilterData({
                      ...filterData,
                      SubSector: e.target.value?.trim(),
                    })
                  }
                />
                <CsInfoTooltip title="More specific division within a sector, focusing on a particular product, service, or market niche." />
              </div>
            </Form.Item>
            <Form.Item
              name="keywordsToAvoid"
              label="Keyword(s) to Avoid"
              rules={[
                {
                  pattern: alphanumericaAndSpecialChar,
                  message: "Please enter valid keywords.",
                },
              ]}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip
                  title="Enter keyword(s) separated by comma(s)"
                  trigger={["hover"]}
                  open={isTooltipOpen}
                  onOpenChange={handleTooltipOpenChange}
                >
                  <Input
                    placeholder="Enter keyword(s) separated by comma(s)"
                    autoComplete="off"
                    onChange={(e) => {
                      if (setFilterData) {
                        setFilterData({
                          ...filterData,
                          keywordsToAvoid: e.target.value?.trim(),
                        });
                      }
                      setIsTooltipOpen(false);
                    }}
                  />
                </Tooltip>
                <CsInfoTooltip title="Enter key words to exclude from the search process." />
              </div>
            </Form.Item>

            <Form.Item
              name="description"
              label="Description"
              rules={[
                {
                  validator: async (_, value) => {
                    if (value) {
                      if (!descriptionRegex.test(value)) {
                        throw new Error("Please enter valid description.");
                      }
                      if (!textWithoutSpace.test(value)) {
                        throw new Error(
                          "This field must not contain blank spaces only."
                        );
                      }
                      if (value.length > 5000) {
                        throw new Error(
                          "Exceeded maximum length of 5000 characters"
                        );
                      }
                    }
                  },
                },
              ]}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Input.TextArea
                  placeholder="Enter description"
                  autoComplete="off"
                  onChange={(e) =>
                    setFilterData &&
                    setFilterData({
                      ...filterData,
                      description: e.target.value?.trim(),
                    })
                  }
                />
                <CsInfoTooltip title="Add additional information to elaborate your query. " />
              </div>
            </Form.Item>
            <Form.Item
              name="regionName"
              label="Region"
              rules={[
                { required: true, message: "This is a required field." },
                {
                  pattern: alphanumericaAndSpecialChar,
                  message: "Please enter valid region.",
                },
              ]}
            >
              <div style={{ display: "flex", alignItems: "center" }}>
                <Input
                  placeholder="Enter region"
                  autoComplete="off"
                  onChange={(e) =>
                    setFilterData &&
                    setFilterData({
                      ...filterData,
                      regionName: e.target.value?.trim(),
                    })
                  }
                />
                <CsInfoTooltip title="Define Region from where you would like to generate data from." />
              </div>
            </Form.Item>
            {/* {forSpecificCompany ? "SPECIFIC" : "NOT"}
            {generateGrowthOpportunities ? "GENERATED" : "NOT"} */}
            <Form.Item name="generateCompany" valuePropName="checked">
              <Checkbox onChange={specificCompanyChange}>
                For Specific Company?{" "}
              </Checkbox>
            </Form.Item>
            <Form.Item
              noStyle
              shouldUpdate={(prevValues, currentValues) =>
                prevValues.generateCompany !== currentValues.generateCompany
              }
            >
              {({ getFieldValue }) =>
                getFieldValue("generateCompany") &&
                !getFieldValue("generateGrowthOpportunities") && (
                  <Form.Item
                    name="company"
                    label="Company"
                    rules={[
                      {
                        pattern: alphanumericaAndSpecialChar,
                        message: "Please enter valid company.",
                      },
                    ]}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Input
                        placeholder="Enter company "
                        autoComplete="off"
                        onChange={(e) =>
                          setFilterData &&
                          setFilterData({
                            ...filterData,
                            company: e.target.value,
                          })
                        }
                      />
                      <CsInfoTooltip title="A Company may be entered in this field if you are looking for company-specific Strategic Imperatives (SIs)." />
                    </div>
                  </Form.Item>
                )
              }
            </Form.Item>
            <Form.Item name="additionalSources" label="Additional Source(s)">
              <div style={{ display: "flex", alignItems: "center" }}>
                <Tooltip
                  title="Enter sources using URL links separated by comma(s)"
                  trigger={["hover"]}
                  open={isAdditionalSourcesTooltipOpen}
                  onOpenChange={handleAdditionalSourcesTooltipOpenChange}
                >
                  <Input
                    placeholder="Enter sources using URL links separated by comma(s)"
                    autoComplete="off"
                    onChange={(e) => {
                      if (setFilterData) {
                        setFilterData({
                          ...filterData,
                          additionalSources: e.target.value,
                        });
                      }
                      setIsAdditionalSourcesTooltipOpen(false);
                    }}
                  />
                </Tooltip>
                <CsInfoTooltip title="Enlist any URL you would like to include during this search process." />
              </div>
            </Form.Item>
            <Form.Item
              name="generateGrowthOpportunities"
              valuePropName="checked"
            >
              <Checkbox onChange={onChange}>
                Generate Growth Opportunity?
              </Checkbox>
            </Form.Item>

            {generateGrowthOpportunities && !forSpecificCompany && (
              <>
                <Form.Item
                  name="growthProcess"
                  label="Growth Process"
                  rules={[
                    {
                      required: true,
                      message: "Please select growth process ",
                      type: "array",
                    },
                  ]}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Select
                      mode="multiple"
                      placeholder="Select growth process"
                      allowClear
                      loading={isGrowthProcessLoading}
                      showSearch={false}
                      options={growthProcessList?.data?.map((item) => ({
                        label: item?.name,
                        value: item?.id,
                      }))}
                      value={selectedValues}
                      onChange={handleChange}
                      dropdownRender={(menu) => (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "8px",
                            }}
                          >
                            <Buttons
                              type="link"
                              onClick={
                                selectedValues?.length ===
                                growthProcessList?.data?.length
                                  ? handleUnselectAll
                                  : handleSelectAll
                              }
                            >
                              {selectedValues?.length ===
                              growthProcessList?.data?.length
                                ? "Unselect All"
                                : "Select All"}
                            </Buttons>
                          </div>
                          {menu}
                        </div>
                      )}
                      tagRender={(props) => {
                        if (
                          selectedValues?.length ===
                          growthProcessList?.data?.length
                        ) {
                          return <Tag color="blue">All Selected</Tag>;
                        }
                        return (
                          <Tag
                            closable={props.closable}
                            onClose={props.onClose}
                          >
                            {props.label}
                          </Tag>
                        );
                      }}
                      maxTagCount={
                        selectedValues?.length ===
                        growthProcessList?.data?.length
                          ? 0
                          : undefined
                      }
                      style={
                        selectedValues?.length ===
                        growthProcessList?.data?.length
                          ? { backgroundColor: "#e6f7ff" }
                          : {}
                      }
                    />
                    <CsInfoTooltip title="Select the Growth Process you need to generate the Growth Opportunity for." />
                  </div>
                </Form.Item>
                <Form.Item
                  name="growthPerspective"
                  label="Growth Perspective"
                  rules={[
                    {
                      required: true,
                      message: "Please select growth perspective",
                      type: "array",
                    },
                  ]}
                >
                  <div style={{ display: "flex", alignItems: "center" }}>
                    <Select
                      mode="multiple"
                      placeholder="Select growth perspective"
                      allowClear
                      loading={isGrowthPerspectiveLoading}
                      showSearch={false}
                      options={growthPerspectiveList?.data?.map((item) => ({
                        label: item?.name,
                        value: item?.id,
                      }))}
                      value={perspectiveValue}
                      onChange={handleChangePerspective}
                      dropdownRender={(menu) => (
                        <div>
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              padding: "8px",
                            }}
                          >
                            <Buttons
                              type="link"
                              onClick={
                                perspectiveValue?.length ===
                                growthPerspectiveList?.data?.length
                                  ? handleUnselectAllPerspective
                                  : handleSelectAllPerspective
                              }
                            >
                              {perspectiveValue?.length ===
                              growthPerspectiveList?.data?.length
                                ? "Unselect All"
                                : "Select All"}
                            </Buttons>
                          </div>
                          {menu}
                        </div>
                      )}
                      tagRender={(props) => {
                        if (
                          perspectiveValue?.length ===
                          growthPerspectiveList?.data?.length
                        ) {
                          return <Tag color="blue">All Selected</Tag>;
                        }
                        return (
                          <Tag
                            closable={props.closable}
                            onClose={props.onClose}
                          >
                            {props.label}
                          </Tag>
                        );
                      }}
                      maxTagCount={
                        perspectiveValue?.length ===
                        growthPerspectiveList?.data?.length
                          ? 0
                          : undefined
                      }
                      style={
                        perspectiveValue?.length ===
                        growthPerspectiveList?.data?.length
                          ? { backgroundColor: "#e6f7ff" }
                          : {}
                      }
                    />
                    <CsInfoTooltip title="Select the Growth Perspective you need to generate the Growth Opportunity for." />
                  </div>
                </Form.Item>
                <div className="form-group">
                  <Form.Item
                    name="details"
                    label="Additional Details"
                    rules={[
                      {
                        validator: async (_, value) => {
                          if (value) {
                            if (!descriptionRegex.test(value)) {
                              throw new Error("Please enter valid details.");
                            }
                            if (!textWithoutSpace.test(value)) {
                              throw new Error(
                                "This field must not contain blank spaces only."
                              );
                            }
                            if (value.length > 5000) {
                              throw new Error(
                                "Exceeded maximum length of 5000 characters"
                              );
                            }
                          }
                        },
                      },
                    ]}
                  >
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Input.TextArea
                        placeholder="Enter additional details"
                        autoComplete="off"
                        onChange={(e) =>
                          setFilterData &&
                          setFilterData({
                            ...filterData,
                            growthInformations: {
                              ...filterData.growthInformations,
                              additionalDetails: e.target.value,
                            },
                          })
                        }
                      />
                      <CsInfoTooltip title="Add additional information to elaborate your query. " />
                    </div>
                  </Form.Item>
                </div>
              </>
            )}
            <div className="submit-holder">
              <div className="submit-holder">
                <Form.Item>
                  <Button
                    variant={"accent"}
                    className="w-full"
                    type="submit"
                    disabled={
                      si8PoolingStatus == "pooling" ||
                      isGrowthOpportunityPolling ||
                      postRequestLoading
                    }
                  >
                    Generate
                  </Button>
                </Form.Item>
              </div>
            </div>
            <div>
              <div className="text-destructive font-bold">
                <b>* Mandatory filters</b>
              </div>
              <div>
                {" "}
                <b>Note to Analysts: </b>The plagiarism policy applies to the
                above model and the output is for internal use only.
              </div>
            </div>

            <div className="h-[200px]"></div>
          </div>
        </Form>
      </div>
    </SidebarWrapper>
  );
};

export default Sidebar;

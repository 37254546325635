import { Mail } from "lucide-react";
import { FooterWrapper } from "./footer.styled";

const Footer = () => {
  const year = new Date().getFullYear();

  return (
    <>
      <footer className="border-t-2 bg-[#17426B] p-3 px-4 text-sm text-muted-foreground  flex items-center justify-between gap-3 flex-wrap fixed bottom-0 z-[48] w-screen font-semibold left-0">
        <div className="flex items-center gap-2 text-white">
          <Mail />
          Support: tm.support@frost.com
        </div>
        <div className="text-[#F28E36]">
          © {year} Frost & Sullivan | All Rights Reserved
        </div>
        <div className="select-none opacity-0 hidden md:block">
          Frost & Sullivan | All Rights Reserved
        </div>
      </footer>
      {/* <FooterWrapper>
        <div className="container">
          <div className="footer-nav">
            <div className="footer-nav-start">
              <p className="contact-us">
                <i className="material-icons">mail_outline</i> Support:
                tm.support@frost.com{" "}
              </p>
            </div>
            <div className="footer-nav-end">
              <p>© {year} Frost & Sullivan | All Rights Reserved </p>
            </div>
          </div>
        </div>
      </FooterWrapper> */}
    </>
  );
};

export default Footer;

/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { FResponseDto_1OfOfAbp_PagedResultDto_1OfOfFGrowthOpportunitiesCompletedDataRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_Volo_Abp_Ddd_Application_Contracts } from '../models/FResponseDto_1OfOfAbp_PagedResultDto_1OfOfFGrowthOpportunitiesCompletedDataRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_Volo_Abp_Ddd_Application_Contracts';
import type { FResponseDto_1OfOfFResponseDtoWithTotalCount_1OfOfFPagedGrowthOpportunitiesDataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/FResponseDto_1OfOfFResponseDtoWithTotalCount_1OfOfFPagedGrowthOpportunitiesDataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { FResponseDto_1OfOfFResponseDtoWithTotalCount_1OfOfFPagedGrowthOpportunitiesDraftDataResponse_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/FResponseDto_1OfOfFResponseDtoWithTotalCount_1OfOfFPagedGrowthOpportunitiesDraftDataResponse_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { FResponseDto_1OfOfFSi8_Dtos_GrowthOpportunitiesData_GrowthDraftRequestInfoDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/FResponseDto_1OfOfFSi8_Dtos_GrowthOpportunitiesData_GrowthDraftRequestInfoDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { FResponseDto_1OfOfFSi8_Dtos_GrowthOpportunitiesData_GrowthOpportunitiesCreateResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/FResponseDto_1OfOfFSi8_Dtos_GrowthOpportunitiesData_GrowthOpportunitiesCreateResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { FResponseDto_1OfOfList_1OfOfFSi8_Dtos_GrowthOpportunitiesData_GrowthOpportunitiesDropdownDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null } from '../models/FResponseDto_1OfOfList_1OfOfFSi8_Dtos_GrowthOpportunitiesData_GrowthOpportunitiesDropdownDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null';
import type { FResponseDto_1OfOfSystem_String } from '../models/FResponseDto_1OfOfSystem_String';
import type { FSi8_Dtos_GrowthOpportunitiesData_GrowthOpportunitiesDataInputDto } from '../models/FSi8_Dtos_GrowthOpportunitiesData_GrowthOpportunitiesDataInputDto';
import type { FSi8_Dtos_GrowthOpportunitiesData_InputUpdateDraftDataDto } from '../models/FSi8_Dtos_GrowthOpportunitiesData_InputUpdateDraftDataDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class GrowthOpportunitiesDataRequestService {

    /**
     * @param requestBody
     * @returns FResponseDto_1OfOfFSi8_Dtos_GrowthOpportunitiesData_GrowthOpportunitiesCreateResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static postCoreApiApiAppGrowthOpportunitiesDataRequestGrowthOpportunitiesRequest(
        requestBody?: FSi8_Dtos_GrowthOpportunitiesData_GrowthOpportunitiesDataInputDto,
    ): CancelablePromise<FResponseDto_1OfOfFSi8_Dtos_GrowthOpportunitiesData_GrowthOpportunitiesCreateResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/growth-opportunities-data-request/growth-opportunities-request',
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param growthOpportunitiesDataRequestId
     * @returns FResponseDto_1OfOfFSi8_Dtos_GrowthOpportunitiesData_GrowthOpportunitiesCreateResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getCoreApiApiAppGrowthOpportunitiesDataRequestGrowthOpportunitiesProcessingStatus(
        growthOpportunitiesDataRequestId: string,
    ): CancelablePromise<FResponseDto_1OfOfFSi8_Dtos_GrowthOpportunitiesData_GrowthOpportunitiesCreateResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/growth-opportunities-data-request/growth-opportunities-processing-status/{growthOpportunitiesDataRequestId}',
            path: {
                'growthOpportunitiesDataRequestId': growthOpportunitiesDataRequestId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param growthOpportunitiesDataRequestId
     * @param growthProcess
     * @param growthPerspective
     * @param company
     * @param programName
     * @param additionalDetails
     * @param searchKeyword
     * @param sortOrder
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @returns FResponseDto_1OfOfFResponseDtoWithTotalCount_1OfOfFPagedGrowthOpportunitiesDataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getCoreApiApiAppGrowthOpportunitiesDataRequestGrowthOpportunitiesDataList(
        growthOpportunitiesDataRequestId: string,
        growthProcess?: string,
        growthPerspective?: string,
        company?: string,
        programName?: string,
        additionalDetails?: string,
        searchKeyword?: string,
        sortOrder?: string,
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
    ): CancelablePromise<FResponseDto_1OfOfFResponseDtoWithTotalCount_1OfOfFPagedGrowthOpportunitiesDataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/growth-opportunities-data-request/growth-opportunities-data-list/{growthOpportunitiesDataRequestId}',
            path: {
                'growthOpportunitiesDataRequestId': growthOpportunitiesDataRequestId,
            },
            query: {
                'GrowthProcess': growthProcess,
                'GrowthPerspective': growthPerspective,
                'Company': company,
                'ProgramName': programName,
                'AdditionalDetails': additionalDetails,
                'SearchKeyword': searchKeyword,
                'SortOrder': sortOrder,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param growthOpportunitiesRequestId
     * @returns FResponseDto_1OfOfFSi8_Dtos_GrowthOpportunitiesData_GrowthOpportunitiesCreateResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static postCoreApiApiAppGrowthOpportunitiesDataRequestRegenerateGrowthOpportunitiesData(
        growthOpportunitiesRequestId: string,
    ): CancelablePromise<FResponseDto_1OfOfFSi8_Dtos_GrowthOpportunitiesData_GrowthOpportunitiesCreateResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/core-api/api/app/growth-opportunities-data-request/regenerate-growth-opportunities-data/{growthOpportunitiesRequestId}',
            path: {
                'growthOpportunitiesRequestId': growthOpportunitiesRequestId,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param searchKeyword
     * @param sortOrder
     * @param fromDate
     * @param toDate
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @returns FResponseDto_1OfOfAbp_PagedResultDto_1OfOfFGrowthOpportunitiesCompletedDataRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_Volo_Abp_Ddd_Application_Contracts Success
     * @throws ApiError
     */
    public static getCoreApiApiAppGrowthOpportunitiesDataRequestGrowthOpportunitiesGenerationHistory(
        searchKeyword?: string,
        sortOrder?: string,
        fromDate?: string,
        toDate?: string,
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
    ): CancelablePromise<FResponseDto_1OfOfAbp_PagedResultDto_1OfOfFGrowthOpportunitiesCompletedDataRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_Volo_Abp_Ddd_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/growth-opportunities-data-request/growth-opportunities-generation-history',
            query: {
                'SearchKeyword': searchKeyword,
                'SortOrder': sortOrder,
                'FromDate': fromDate,
                'ToDate': toDate,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns FResponseDto_1OfOfList_1OfOfFSi8_Dtos_GrowthOpportunitiesData_GrowthOpportunitiesDropdownDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getCoreApiApiAppGrowthOpportunitiesDataRequestGrowthProcesses(): CancelablePromise<FResponseDto_1OfOfList_1OfOfFSi8_Dtos_GrowthOpportunitiesData_GrowthOpportunitiesDropdownDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/growth-opportunities-data-request/growth-processes',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @returns FResponseDto_1OfOfList_1OfOfFSi8_Dtos_GrowthOpportunitiesData_GrowthOpportunitiesDropdownDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getCoreApiApiAppGrowthOpportunitiesDataRequestGrowthPerspectives(): CancelablePromise<FResponseDto_1OfOfList_1OfOfFSi8_Dtos_GrowthOpportunitiesData_GrowthOpportunitiesDropdownDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/growth-opportunities-data-request/growth-perspectives',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param growthOpportunitiesRequestId
     * @param requestBody
     * @returns FResponseDto_1OfOfFSi8_Dtos_GrowthOpportunitiesData_GrowthDraftRequestInfoDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static putCoreApiApiAppGrowthOpportunitiesDataRequestGrowthOpportunitiesGeneratedData(
        growthOpportunitiesRequestId: string,
        requestBody?: FSi8_Dtos_GrowthOpportunitiesData_InputUpdateDraftDataDto,
    ): CancelablePromise<FResponseDto_1OfOfFSi8_Dtos_GrowthOpportunitiesData_GrowthDraftRequestInfoDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/core-api/api/app/growth-opportunities-data-request/growth-opportunities-generated-data/{growthOpportunitiesRequestId}',
            path: {
                'growthOpportunitiesRequestId': growthOpportunitiesRequestId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param growthOpportunitiesDraftDataId
     * @param requestBody
     * @returns FResponseDto_1OfOfSystem_String Success
     * @throws ApiError
     */
    public static putCoreApiApiAppGrowthOpportunitiesDataRequestGrowthOpportunitiesDraftData(
        growthOpportunitiesDraftDataId: string,
        requestBody?: FSi8_Dtos_GrowthOpportunitiesData_InputUpdateDraftDataDto,
    ): CancelablePromise<FResponseDto_1OfOfSystem_String> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/core-api/api/app/growth-opportunities-data-request/growth-opportunities-draft-data/{growthOpportunitiesDraftDataId}',
            path: {
                'growthOpportunitiesDraftDataId': growthOpportunitiesDraftDataId,
            },
            body: requestBody,
            mediaType: 'application/json',
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param growthOpportunitiesDraftDataRequestId
     * @param growthProcess
     * @param growthPerspective
     * @param company
     * @param programName
     * @param additionalDetails
     * @param searchKeyword
     * @param sortOrder
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @returns FResponseDto_1OfOfFResponseDtoWithTotalCount_1OfOfFPagedGrowthOpportunitiesDraftDataResponse_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null Success
     * @throws ApiError
     */
    public static getCoreApiApiAppGrowthOpportunitiesDataRequestGrowthOpportunitiesDraftDataList(
        growthOpportunitiesDraftDataRequestId: string,
        growthProcess?: string,
        growthPerspective?: string,
        company?: string,
        programName?: string,
        additionalDetails?: string,
        searchKeyword?: string,
        sortOrder?: string,
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
    ): CancelablePromise<FResponseDto_1OfOfFResponseDtoWithTotalCount_1OfOfFPagedGrowthOpportunitiesDraftDataResponse_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/growth-opportunities-data-request/growth-opportunities-draft-data-list/{growthOpportunitiesDraftDataRequestId}',
            path: {
                'growthOpportunitiesDraftDataRequestId': growthOpportunitiesDraftDataRequestId,
            },
            query: {
                'GrowthProcess': growthProcess,
                'GrowthPerspective': growthPerspective,
                'Company': company,
                'ProgramName': programName,
                'AdditionalDetails': additionalDetails,
                'SearchKeyword': searchKeyword,
                'SortOrder': sortOrder,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

    /**
     * @param searchKeyword
     * @param sortOrder
     * @param fromDate
     * @param toDate
     * @param sorting
     * @param skipCount
     * @param maxResultCount
     * @returns FResponseDto_1OfOfAbp_PagedResultDto_1OfOfFGrowthOpportunitiesCompletedDataRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_Volo_Abp_Ddd_Application_Contracts Success
     * @throws ApiError
     */
    public static getCoreApiApiAppGrowthOpportunitiesDataRequestGrowthOpportunitiesDraftHistory(
        searchKeyword?: string,
        sortOrder?: string,
        fromDate?: string,
        toDate?: string,
        sorting?: string,
        skipCount?: number,
        maxResultCount?: number,
    ): CancelablePromise<FResponseDto_1OfOfAbp_PagedResultDto_1OfOfFGrowthOpportunitiesCompletedDataRequestResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null_Volo_Abp_Ddd_Application_Contracts> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/core-api/api/app/growth-opportunities-data-request/growth-opportunities-draft-history',
            query: {
                'SearchKeyword': searchKeyword,
                'SortOrder': sortOrder,
                'FromDate': fromDate,
                'ToDate': toDate,
                'Sorting': sorting,
                'SkipCount': skipCount,
                'MaxResultCount': maxResultCount,
            },
            errors: {
                400: `Bad Request`,
                401: `Unauthorized`,
                403: `Forbidden`,
                404: `Not Found`,
                500: `Server Error`,
                501: `Server Error`,
            },
        });
    }

}

import styled from "styled-components";
import { colors } from "../../constants/themeConstants";

export const Container = styled.main`
  /* max-width: none;
  width: 100%;
  padding: 20px 40px;
  min-height: calc(100vh - 98px); */

  .outlet-flex-container {
    display: flex;
    flex-direction: row;
    gap: 0.5rem;
    margin-bottom: 1.5rem;
    border-bottom: 1px solid #459bd0;
  }

  .outlet-flex-container-go {
    display: flex;
    flex-direction: row;
    gap: 1.5rem;
    margin-bottom: 1rem;
    margin-left: 0.5rem;
  }

  .nav-bar-link {
    color: #172d55;
    text-decoration: none;
    padding: 10px 15px 6px 15px;
    border-bottom: 4px solid transparent;
  }
  .outlet-flex-container .active {
    // text-decoration: underline;
    // text-underline-offset: 8px;
    // text-decoration-thickness: 3px;
    /* color: #172d55;
    font-weight: 600;
    border-radius: 6px;
    padding: 5px 10px;
    border-left: 4px solid #172d55; */
    background-color: #1582c52b;
    border-radius: 5px 5px 0 0;
    border-bottom: 4px solid #459bd0;
    font-weight: 700;
    color: #1582c5;
  }
  .outlet-flex-container-go .active {
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
  }
  .nested-bar-link {
    color: #17426b;
    text-decoration: none;
  }

  .nested-bar-link .active {
    text-decoration: underline;
    text-underline-offset: 8px;
    text-decoration-thickness: 3px;
  }
  .modalBox {
    max-width: 100%;
    background: #dedede;
    border-radius: 0.5rem;
    margin-top: 1rem;
  }

  .InnerBoxModal {
    background-color: #172e55;
    color: white;
    display: flex;
    align-items: center;
    padding: 8px;
    font-size: 12px;
    border-radius: 0.5rem;
  }

  .InnerBoxModalText {
    color: white;
    padding-left: 0.6rem;
    font-size: 12px;
  }

  .BodyModalText {
    margin-top: 1.5rem;
    width: 45vw;
    text-align: center;
    font-size: 12px;
  }

  .BodyModal {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 65vh;
    font-size: 12px;
    color: #172e55;
  }

  .float {
    position: fixed;
    width: 60px;
    height: 60px;
    bottom: 90px;
    right: 10px;
    background-color: #172d55;
    border-radius: 50px;
    text-align: center;
    box-shadow: 2px 2px 3px #999;
    z-index: 9999;
  }

  .my-float {
    color: white;
    margin-top: 20px;
  }

  .history-btn {
    /* margin-top: -18px; */
    display: flex;
    width: 100%;
    justify-content: end;
    width: fit-content;
    height: fit-content;
    &-style {
      /* padding: 10px 20px 2px 20px !important; */
      padding: 10px 26px !important;
      background: #172d55 !important;
      color: #fff !important;
      border-radius: 8px;
      font-size: 14px;
      line-height: 14px;
      /* box-shadow: 8px 2px 25px -12px rgba(0, 0, 0, 0.75); */
    }
  }

  .box-holder {
    transition: padding 0.3s ease-in-out;

    h1 {
      text-transform: none;
    }

    .tabset {
      margin: 0;
      padding: 0;
      list-style: none;
      display: flex;

      display: flex;
      /* align-items: center; */
      align-items: flex-end;
      justify-content: space-between;
      gap: 20px;
      flex-wrap: wrap;
      .left-list {
        display: flex;
        flex-wrap: wrap;
        list-style-type: none;
        margin: 0;
        padding: 0;
        @media screen and (max-width: 500px) {
          gap: 10px;
        }
      }

      // border-bottom: 1px solid #555;
      margin-bottom: 20px;
      font-size: 13.33px;
      font-family: "Nunito Sans";
      svg {
        width: 20px;
        height: 20px;
      }
      li {
        margin-right: 10px;
        margin-bottom: -2px;
        font-size: 14px;
        button {
          cursor: pointer;
          text-decoration: none;
          color: #555;
          background: none;
          position: relative;
          display: block;
          padding-left: 25px;
          padding-bottom: 5px;
          font-size: 12px;
          border: none;
          border-bottom: 4px solid transparent;
          white-space: nowrap;
          &:hover,
          &.active {
            border-color: #17426b;
            color: #17426b;
          }
        }
      }
    }
  }

  /* .tabset .tabset button:hover,
  .tabset button.active {
    border-color: #17426b;
    color: #17426b;
  } */

  .tabset svg {
    position: absolute;
    left: 0;
    top: 0;
  }

  .tab-content {
    position: relative;
    /* z-index: 1; */
    min-height: calc(100vh - 283px);
    overflow: hidden;
    // background: rgba(243, 244, 246, 1);
  }

  .tab {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.3s ease-in-out, visibility 0.3s ease-in-out;
  }

  .tab.active {
    opacity: 1;
    visibility: visible;
    position: static;
  }

  .tab-content .pagination-btn {
    width: 35px;
    height: 35px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    border: none;
    background-color: white;
    &:hover {
      background-color: #efefef;
    }
  }
  .pagination-btn .next-icon {
    padding-left: 1px;
  }
  .tab-content .pagination-position {
    bottom: 40px;
    right: 26px;
  }

  .btn {
    color: #fff;
    text-align: center;
    display: flex;
    align-items: center;
    gap: 3px;
    vertical-align: top;
    line-height: 1.3;
    font-weight: 700;
    border: none;
    text-decoration: none;
    font-family: "Nunito Sans";
    border-radius: 8px;
    padding: 9px 18px;
    font-size: 14px;
    cursor: pointer;
    border: 1px solid transparent;
    svg {
      margin-left: 10px;
      vertical-align: top;
    }
    &.export-btn {
      background: #172d55;
      &:hover {
        background: #031941;
      }
      svg {
        display: inline-block;
        stroke: currentcolor;
        fill: currentcolor;
        width: 20px;
        height: 20px;
      }
    }
    &.secondary-outline {
      background-color: transparent;
      color: #24457e;
      border: 1px solid #24457e;
      &:hover {
        background: #24457e;
        color: #fff;
      }
    }
  }
`;

export const HeaderBoxWrapper = styled.div`
  .box-header .box-wrap {
    padding: 10px 0;
  }

  .completion-date {
    display: flex;
    padding: 26px 0px 0px 0px;
    font-size: small;
    align-items: end;
    justify-content: flex-end;
    &-title {
      padding-right: 5px;
      font-weight: 800;
      color: #17426b;
    }
  }
  .regenerate-btn {
    background: rgba(255, 255, 255, 1);
  }
  .box-wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 14px;

    justify-content: flex-end;
  }

  .box-wrap .btn {
    font-size: 12px;
  }

  .search-holder {
    /* height: 47px; */
    width: 100%;
  }

  .search-holder input {
    background: #fff;
    border: 1px solid #dfe3e8;
    border-radius: 6px;
    display: block;
    font-family: "Nunito Sans";
    height: 37px;
    padding: 8px 20px;
    width: 100%;
    &:focus {
      box-shadow: none;
      border: none;
    }
    &::placeholder {
      color: ${colors?.secondary};
    }
  }

  .box-header .header-list h2 {
    // margin-top: 5px;
    text-transform: none;
    font-size: 22px;
  }

  .display-none {
    display: none;
  }
  .expire-container {
    flex-direction: column-reverse;
    width: 22.1rem;
    align-items: flex-end;
    button {
      width: fit-content;
    }
  }

  @media (max-width: 768px) {
    .expire-container {
      flex-direction: column;
      align-items: end;
    }

    .completion-date {
      margin-bottom: 8px;
    }
  }

  @media (min-width: 768px) {
    .box-wrap {
      width: 500px;
      display: flex;
      flex-wrap: wrap;
      align-items: center;
      flex-shrink: 0;
    }

    .box-wrap .search-holder {
      flex: 1;
    }

    .header-list ::-webkit-scrollbar {
      width: 10px;
    }

    .header-list ::-webkit-scrollbar-track {
      box-shadow: inset 0 0 6px rgba(23, 45, 85, 0.3);
    }

    .header-list ::-webkit-scrollbar-thumb {
      background: #172d55;
    }

    .box-header {
      display: flex;
      flex-direction: row;
      // align-items: center;
      justify-content: space-between;
      margin-bottom: 0px;
    }

    .box-header h1 {
      flex: 1;
    }
  }
  @media (min-width: 1024px) {
    .box-wrap {
      width: 480px;
    }
  }
  @media (min-width: 1200px) {
    .box-wrap {
      width: 585px;
    }
  }
`;

export const TimeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: ${colors.secondary};
  font-size: 50px;
  font-weight: 600;
  margin-top: 8rem;
  .loading-text {
    font-size: 60px;
    font-weight: 400;
  }
`;

export const StyledConfirmation = styled.div`
  background: rgba(0, 0, 0, 0.5);
  position: absolute;
  .modal-dialog {
    height: 100%;
    margin: 0 auto;
    display: flex;
    align-items: center;
    width: 100%;
  }
`;

export const InfoMessageContainer = styled.span`
  background: ${colors.secondary};
  color: #fff;
  display: block;
  font-size: 15px;
  padding: 4px 0px 4px 49px;
  position: relative;
  fontstyle: italic;
  svg {
    height: 26px;
    left: 15px;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 26px;
  }
`;

import {
  type UseMutationResult,
  useMutation,
  // useQueryClient,
} from "react-query";
import {
  Si8DataRequestService,
  type FResponseDto_1OfOfFSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null,
} from "../../services/frost-si8-services";
import { toast } from "react-toastify";
import { useDispatch } from "react-redux";
import { removeGrowthOpportunities } from "../../store/slices/GrowthSlice";

const usePostCancelSi8DataRequest = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  setSi8DataResponse?: any,
  setButtonRest?: (value: boolean) => void
): UseMutationResult<
  | FResponseDto_1OfOfFSi8_Dtos_SI8DataRequests_PagedSI8DataResponseDto_FSi8_Application_Contracts_Version_1_0_0_0_Culture_neutral_PublicKeyToken_null
  | undefined,
  unknown,
  any,
  unknown
> => {
  const dispatch = useDispatch();
  return useMutation(
    async (si8RequestID: string) => {
      return await handleRequest(
        Si8DataRequestService.postCoreApiApiAppSi8DataRequestCancelSI8DataRequest(
          si8RequestID
        )
      );
    },
    {
      onSuccess: async (response: any | undefined) => {
        if (response != null) {
          setButtonRest && setButtonRest(false);
          setSi8DataResponse(response?.data);
          dispatch(removeGrowthOpportunities());
          toast.success("Data generation has been cancelled successfully");
        }
      },
      onError: (err) => {
        console.log("error", err);
      },
    }
  );
};

export default usePostCancelSi8DataRequest;

import { NavLink, Outlet, useLocation, useParams } from "react-router-dom";
import WaterMarker from "../../components/watermark";
import { Button, Flex } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { clearGrowthOpportunitiesRequestId } from "../../store/slices/GrowthSlice";
import { cn } from "@/lib/utils";
const GrowthOpportunity = () => {
  const { id } = useParams<{ id: string }>();
  const { pathname } = useLocation();

  const dispatch = useDispatch();

  // Get the idFromSavedDraft from Redux store
  const idFromSavedDraft = useSelector(
    (state: RootState) =>
      state.growthOpportunitiesSlice.growthOpportunitiesRequestId
  );

  // Remove the ID from Redux store before navigating to another page
  const handleNavLinkClick = () => {
    dispatch(clearGrowthOpportunitiesRequestId());
  };
  return (
    <div style={{ marginBottom: "1.5rem" }}>
      <nav className="outlet-flex-container-go">
        <Flex justify="space-between" align="center" style={{ width: "100%" }}>
          <Flex gap={"1.5rem"}>
            <NavLink
              className={cn({
                " !underline !decoration-3 !underline-offset-8": checkIfActive(
                  ["growth-Table", "level-1-report", "growth-history"],
                  pathname
                ),
              })}
              to={
                id && idFromSavedDraft
                  ? `/growth-opportunity/growth-Table/${idFromSavedDraft}`
                  : "/growth-opportunity/growth-Table"
              }
              onClick={handleNavLinkClick}
            >
              Level 1-Report
            </NavLink>
            <NavLink
              to="/growth-opportunity/saved-report-Table"
              className={cn({
                " !underline !decoration-3 !underline-offset-8": checkIfActive(
                  ["saved-report-Table", "saved-report"],
                  pathname
                ),
              })}
            >
              Saved Reports
            </NavLink>
          </Flex>
          <NavLink to="/growth-opportunity/growth-history" className={'!px-4'}>
            <Button type="primary">Generate History</Button>
          </NavLink>
        </Flex>
      </nav>
      <div>
        {/* <WaterMarker> */}
        <div className={cn("tab-content", { "rounded-sm": true })}>
          <Outlet />
        </div>
        {/* </WaterMarker> */}
      </div>
    </div>
  );
};

export default GrowthOpportunity;

export const checkIfActive = (
  props: string | string[],
  pathname: string,
  index: number = 2
): boolean => {
  const pathProps = pathname.split("/")?.[index];
  if (typeof props === "string") {
    return props === pathProps;
  }

  if (Array.isArray(props)) {
    return props.some((item) => item === pathProps);
  }

  return false;
};

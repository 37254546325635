"use client";

import moment from "moment";
import { useEffect, useState } from "react";
import { ViewWrapper } from "../../pages/update/update.styled";
import parse from "html-react-parser";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "../ui/carousel";
import { cn } from "@/lib/utils";
import { Card } from "../ui/card";
// If you have a utils file with a cn function, use this:
// Or if you don't have a cn function, you can define it inline:
// const cn = (...classes) => classes.filter(Boolean).join(' ');

function UpdateCarousel({
  rowData,
  closeCarouselModal,
  notifStatusChange,
  setRecordedIds,
}) {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [notifData, setNotifData] = useState<any>(rowData);
  const [api, setApi] = useState<any>();

  const handleSelect = (selectedIndex) => {
    setSelectedIndex(selectedIndex);
    if (selectedIndex !== null && notifData && notifData?.length > 0) {
      const updatedRowData = notifData?.map((item, index) => {
        return {
          ...item,
          notificationId: item.id,
          isSeen: index === selectedIndex ? !item.isSeen : item.isSeen,
          isPopupOpened: item.isPopupOpened,
        };
      });
      setNotifData(updatedRowData);

      const closeNotifyPayload = updatedRowData?.map((item) => ({
        notificationId: item.id,
        isSeen: item.isSeen,
        isPopupOpened: item.isPopupOpened,
      }));
      setRecordedIds(closeNotifyPayload);
    }
  };

  useEffect(() => {
    handleSelect(selectedIndex);
  }, [rowData]);

  useEffect(() => {
    if (!api) return;

    const handleSlideChange = () => {
      const currentIndex = api.selectedScrollSnap();
      setSelectedIndex(currentIndex);
      handleSelect(currentIndex);
    };

    api.on("select", handleSlideChange);

    return () => {
      api.off("select", handleSlideChange);
    };
  }, [api]);

  const goToSlide = (index: number) => {
    if (api) {
      api.scrollTo(index);
    }
  };

  return (
    <div>
      <ViewWrapper notif={true}>
        <Carousel setApi={setApi} className="px-0">
          <CarouselContent>
            {rowData?.map((item, index) => (
              <CarouselItem key={index} className="mx-auto">
                <div className="header">
                  <h2 className="title">{item?.description?.title}</h2>
                  <div className="version-time">
                    <span className="time">
                      Date : {moment(item?.creationTime).format("YYYY-MM-DD")}
                    </span>
                    <span className="version">
                      {" "}
                      Version :{item?.description?.version}
                    </span>
                  </div>
                </div>
                <Card className="p-2 min-h-80">
                  {item?.description?.description
                    ? parse(
                        item?.description?.description.replace(/(\n)/g, "<br>")
                      )
                    : parse(
                        item?.description?.message.replace(/(\n)/g, "<br>")
                      )}
                </Card>
              </CarouselItem>
            ))}
          </CarouselContent>
          {rowData?.length > 1 ? (
            <>
              <CarouselPrevious className="translate-x-[120%] cursor-pointer" />
              <CarouselNext className="translate-x-[-120%] cursor-pointer" />
            </>
          ) : (
            <></>
          )}

          {/* Slide indicators */}
          {rowData?.length > 1 ? (
            <div className="flex justify-center gap-2 mt-2">
              {rowData?.map((_, index) => (
                <button
                  key={index}
                  onClick={() => goToSlide(index)}
                  className={cn(
                    "w-3 h-3 rounded-full transition-all cursor-pointer",
                    selectedIndex === index
                      ? "bg-[#172d55] scale-110"
                      : "bg-gray-300 hover:bg-gray-400"
                  )}
                  aria-label={`Go to slide ${index + 1}`}
                />
              ))}
            </div>
          ) : (
            <></>
          )}
        </Carousel>
      </ViewWrapper>
    </div>
  );
}

export default UpdateCarousel;

export const AiGenerateFilterInitial = {
  Industry: "",
  regionName: "",
  SubSector: "",
  description: "",
  keywordsToAvoid: "",
  additionalSources: "",
  growthInformations: {
    company: "",
    growthPerspective: [],
    growthProcess: [],
    Sector: "",
    additionalDetails: "",
  },
  generateGrowthOpportunities: false,
};

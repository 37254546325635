/* eslint-disable @typescript-eslint/no-unused-vars */
import { isEmpty } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { UseMutationResult, useMutation, useQueryClient } from "react-query";
import { useDispatch, useSelector } from "react-redux";
import { toast } from "react-toastify";
import UpdateCarousel from "../../components/carousel/carousel";
import CustomModal from "../../components/modal";
import Sidebar from "../../components/sidebarFilter/sidebarFilter";

import { TableParamsType, tableData } from "../../constants/common";
import InitialValuesForm from "../../constants/initialValues";
import {
  useGetAllPopupNotification,
  useGetExcelExport,
  usePostAddEditSi8Data,
  usePostCancelSi8DataRequest,
  usePutNotifStatusChange,
} from "../../hooks";
import useCustomModal from "../../hooks/customHooks/useCusomModal";
import useFrostSi8Api from "../../hooks/useFrostSi8Api";

import { Si8DataRequestService } from "../../services/frost-si8-services";
import { setUpdateFlag } from "../../store/slices/subSegmentSlice";
import { AiGenerateFilterType } from "../../types/common";
import { AiGenerateFilterInitial } from "../../utils/initialValue";
import { stylePrimary } from "../../utils/style";
import { validation } from "../../utils/validation/validations";
import { selectAuthRoutesList } from "../configuration/routeSlice";
import { Container } from "./home.styled";

import { NavLink, Outlet, useParams } from "react-router-dom";
import { FilterProvider } from "../../context/FilterProvider";
import {
  removeGrowthOpportunities,
  setGrowthOpportunitiesId,
  setGrowthOpportunitiesStatus,
} from "../../store/slices/GrowthSlice";
import useGenerateDataGrowthOpportunity from "../../hooks/GrowthOpportunity/PostGrowthopportunityLoader/usePostLoader";
import { RootState } from "../../store/store";
import {
  removeSI8PoolingStatus,
  setPoolingStatus,
} from "../../store/slices/SI8PoolingStatusSlice";
import Navbar from "@/components/navbar/navbar";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { SlidersHorizontal } from "lucide-react";
import Footer from "@/components/footer/footer";

const Home = () => {
  const [toggle, setToggle] = useState(1);
  const [tableInfo, setTableInfo] = useState<TableParamsType>(
    InitialValuesForm.tableParams
  );

  const [sortBy, setSortBy] = useState<any | undefined>();
  const [addNew, setAddNew] = useState(false);
  const [filterData, setFilterData] = useState<AiGenerateFilterType>(
    AiGenerateFilterInitial
  );
  const [filterGeneratedData, setFilterGeneratedData] =
    useState<AiGenerateFilterType>(AiGenerateFilterInitial);
  const { showModal, openModal, closeModal } = useCustomModal();
  const {
    showModal: showFailModal,
    openModal: openFailModal,
    closeModal: closeFailModal,
  } = useCustomModal();
  const {
    showModal: showCarouselModal,
    openModal: openCarouselModal,
    closeModal: closeCarouselModal,
  } = useCustomModal();
  const [si8DataResponse, setSi8DataResponse] = useState<any>();
  const [exportData, setExportData] = useState<any>();
  const tableList = si8DataResponse?.data?.si8DataResults;
  const [data, setData] = useState<any>(tableList);
  const [generatedValues, setGeneratedValues] = useState<any>();
  const [postPayload, setPostPayload] = useState<any>();
  const [searchKeyword, setSearchKeyword] = useState<string | null>(null);
  const [recursiveResponse, setRecursiveResponse] = useState<any>();
  const [showCancelModal, setShowCancelModal] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [rowId, setrowId] = useState<string>();
  const [isExpire, setIsExpire] = useState<boolean>(false);
  const [sorting, setSorting] = useState<string>();
  const [order, setOrder] = useState<string>("");
  const [page, setPage] = useState(1); // Current page
  const [resultsPerPage, setResultsPerPage] = useState(10); // Number of results per page
  const [edited, setEdited] = useState<any>(); // Number of results per page
  const [buttonRest, setButtonRest] = useState<boolean>(false);
  const [showHistoryModal, setShowHistoryModal] = useState<boolean>(false);
  const [isActive, setActive] = useState(false);
  const [start, setStart] = useState("start");
  const [rowData, setRowData] = useState<any>();
  const [recordedIds, setRecordedIds] = useState<any>([]);
  const [tableGenerated, setTableGenerated] = useState<boolean>(false);
  const viewHistoryRef = useRef(false);
  const generateDataRef = useRef(false);

  const [errors, setErrors] = useState<any>({});

  const dispatch = useDispatch();
  const userAuthList = useSelector(selectAuthRoutesList);

  const { si8DataRequestId: si8RequestID } = useParams();

  const si8PoolingStatus = useSelector(
    (state: RootState) => state.si8PoolingStatusSlice.poolingStatus
  );
  const Si8RequestID = useSelector(
    (state: RootState) => state.si8PoolingStatusSlice.si8RequestID
  );

  const rowTemplate = {
    id: "",
    strategicImperative: "",
    event: "",
    impactScore: "",
    impactStartYear: "",
    impactDuration: "",
    durationCurve: "",
    potentialImpactOnRevenue: "",
    siDetail: "",
    sourceName: "Analyst Entry",
    new: true,
    si8DataRequestId: "",
  };

  const addRow = useCallback(() => {
    setAddNew(!addNew);
    const newRow = { ...rowTemplate };
    const newData = [...data, newRow];
    setData(newData);
  }, [addNew, data]);

  const sort = useCallback(
    (key) => {
      const current = {
        key: sortBy.key,
        direction: sortBy.direction,
      };
      if (current["key"] === key) {
        if (current.direction !== "up") {
          current.direction = "up";
        } else {
          current.direction = "down";
        }
      } else {
        current.key = key;
        current.direction = "up";
      }
      setSortBy({ ...current });
    },
    [sortBy]
  );

  useEffect(() => {
    if (location.pathname === "/transformation") {
      setToggle(1);
      setData(undefined);
      setSi8DataResponse(undefined);
    }
  }, [setToggle]);

  useEffect(() => {
    if (rowId) {
      // Make a copy of the array to avoid mutating state directly
      const updatedData = [...data];

      // Access the last object in the array (data.length - 1) and update its values
      const lastObject = updatedData[data.length - 1];
      lastObject.id = rowId;
      lastObject.new = false;

      // Update the state with the new array
      setData(updatedData);
    }
  }, [rowId]);

  useEffect(() => {
    if (edited?.isEdited) {
      // Make a copy of the array to avoid mutating state directly
      const index = data?.findIndex((item) => item?.id === edited?.id);
      if (index !== -1) {
        const updatedArray = [...data];
        updatedArray[index].isEdited = edited?.isEdited;
        updatedArray[index] = edited;
        updatedArray[index].lastModifiedByFullName =
          edited?.lastModifiedByFullName;
        updatedArray[index].lastModificationTime = edited?.lastModificationTime;
        setData(updatedArray);
      }
      // Update the state with the new array
      // setData(updatedData);
    }
  }, [edited]);

  const { handleRequest } = useFrostSi8Api();
  const { mutate: growdthDataGenerate, isLoading: isLoadingGenerate } =
    useGenerateDataGrowthOpportunity(handleRequest);
  const { mutate: notifStatusChange, isLoading: notifLoading } =
    usePutNotifStatusChange(handleRequest);

  const { data: popupdata } = useGetAllPopupNotification(
    handleRequest,
    openCarouselModal
  );

  const { mutate } = usePostAddEditSi8Data(
    handleRequest,
    "",
    setSi8DataResponse,
    si8DataResponse,
    setrowId,
    setEdited,
    data
  );
  //add Data

  const handleRowSubmit = async (rowData1?: any) => {
    const validationErrors = validation(rowData, setErrors);
    if (Object.keys(validationErrors).length === 0 && rowData !== undefined) {
      const updatedData = [...data];
      // Access the last object in the array (data.length - 1) and update its values
      const lastObject = updatedData[data.length - 1];
      lastObject.strategicImperative = rowData?.strategicImperative;
      lastObject.event = rowData?.event;
      lastObject.impactScore = rowData?.impactScore;
      lastObject.impactStartYear = rowData?.impactStartYear;
      lastObject.impactDuration = rowData?.impactDuration;
      lastObject.durationCurve = rowData?.durationCurve;
      lastObject.potentialImpactOnRevenue = rowData?.potentialImpactOnRevenue;
      lastObject.siDetail = rowData?.siDetail;
      setAddNew(false);
      setRowData(undefined);
      await mutate(rowData1 !== undefined ? rowData1 : rowData);
    } else {
      toast.error("Please Fill All required fields");
    }
  };
  const queryClient = useQueryClient();
  const updateHandler = useCallback(
    async (rowId, item) => {
      await mutate(
        { ...(item as any), rowId: rowId },
        {
          onSuccess: async (resp) => {
            await queryClient.invalidateQueries("topTenData");
          },
        }
      );
    },
    [data]
  );

  //delete data
  const deleteHandler = useCallback(
    async (id) => {
      handleRequest(
        Si8DataRequestService?.deleteCoreApiApiAppSi8DataRequestSI8Data(id)
      )
        .then(async (res: any) => {
          if (res != null) {
            const keys: any = data.filter((item) => item.id !== id);
            setData(keys);
            await queryClient.invalidateQueries("topTenData");
            toast.success("Successfully Deleted the item");
            dispatch(setUpdateFlag(true));
          }
        })
        .catch((error: any) => {
          console.log("error", error);
        });
    },
    [data]
  );

  //CountDown
  /* const initialTime = 0.5 * 60; */ // 10 minutes in seconds
  const [showTimer, setShowTimer] = useState(false);
  const [seconds, setSeconds] = useState<number>();

  useEffect(() => {
    let interval: any;
    if (seconds && seconds > 0) {
      interval = setInterval(() => {
        setSeconds((prevSeconds) => {
          return prevSeconds && prevSeconds - 1;
        });
      }, 1000);
    }
    if (seconds === 0 && start === "start") {
      openModal();
    }
    if (recursiveResponse?.si8DataProcessStageSystemName === "failed") {
      clearInterval(interval);
    }

    if (
      si8DataResponse?.data?.si8DataProcessStageSystemName === "completed" ||
      si8DataResponse?.si8DataProcessStageSystemName === "completed"
    ) {
      clearInterval(interval);
    }
    if (
      si8DataResponse?.data?.si8DataProcessStageSystemName === "failed" ||
      si8DataResponse?.si8DataProcessStageSystemName === "failed"
    ) {
      clearInterval(interval);
    }

    return () => clearInterval(interval);
  }, [seconds, si8DataResponse, recursiveResponse]);

  useEffect(() => {
    if (recursiveResponse?.si8DataProcessStageSystemName === "failed") {
      openFailModal();
      setShowTimer(false);
    }
  }, [recursiveResponse]);

  const formatTime = (timeInSeconds: any) => {
    const minutes = Math.floor(timeInSeconds / 60);
    const seconds = timeInSeconds % 60;
    return `${minutes.toString().padStart(2, "0")}:${seconds
      .toString()
      .padStart(2, "0")}`;
  };

  const startTimer = () => {
    // Reset the timer to its initial value
    setSeconds(window?.__RUNTIME_CONFIG__?.VITE_SET_TIMER * 60);
    setShowTimer(true);
  };

  //export Si8 data into CSV
  const csvLinkRef = useRef(null);
  const [exportBtn, setExportBtn] = useState<boolean>(false);

  const handleExport = async () => {
    await setResultsPerPage(si8DataResponse?.totalCount);
    await generateSi8Data(postPayload);
  };

  useEffect(() => {
    if (exportData !== undefined || isEmpty(exportData)) {
      (csvLinkRef.current as any)?.link.click();
      setResultsPerPage(10);
      setExportBtn(false);
    }
  }, [exportData]);

  //Generate data for Si8

  useEffect(() => {
    let intervalId: any | null = null;

    if (si8PoolingStatus == "pooling" && Si8RequestID) {
      const fetchData = () => {
        handleRequest(
          Si8DataRequestService.getCoreApiApiAppSi8DataRequestSI8DataRequestStatus(
            Si8RequestID
          )
        )
          .then((response) => {
            if (response?.data === null) {
              dispatch(removeSI8PoolingStatus());
              toast.error(response?.message);
            }
            if (response && response?.data) {
              if (
                response?.data?.si8DataProcessStageSystemName === "completed"
              ) {
                clearInterval(intervalId);
                dispatch(setPoolingStatus("completed"));
                // dispatch(removeSI8PoolingStatus());
              } else if (
                response?.data?.si8DataProcessStageSystemName === "failed"
              ) {
                clearInterval(intervalId);
                dispatch(removeSI8PoolingStatus());
                openFailModal();
              }
            }
          })
          .catch((error) => {
            dispatch(removeSI8PoolingStatus());
            console.error("Error fetching progress:", error);
          });
      };

      startTimer();
      fetchData();
      intervalId = setInterval(fetchData, 6000);
    }

    return () => {
      clearInterval(intervalId!);
      intervalId = null;
    };
  }, [si8PoolingStatus, handleRequest, dispatch]);

  const usePostSi8DataList = (
    searchKeywords?: string | null,
    resultsPerPage?: number,
    page?: number,
    isExpire?: boolean,
    sorting?: string,
    order?: string
  ): UseMutationResult<any | undefined, unknown, any, unknown> => {
    // const { sortOrder, sorting } = tableInfo;

    return useMutation(
      async (payload: any) => {
        setIsLoading(true);
        const newPayload = {
          ...payload,
          practiceAreaName: payload?.practiceAreaName,
          customTransformationalJourney: payload?.customTransformationalJourney,
          description: payload?.description,
          regionName: payload?.regionName,
          additionalSources: payload?.additionalSources
            ? payload?.additionalSources
            : null,
        };

        if (payload?.customTransformationalJourney) {
          newPayload.customTransformationalJourney =
            payload?.customTransformationalJourney;
        } else {
          delete newPayload.customTransformationalJourney;
        }
        const isPaginationOrSortingChanged =
          searchKeywords || sorting || order || page || resultsPerPage;

        if (isExpire === true) {
          return await handleRequest(
            Si8DataRequestService.postCoreApiApiAppSi8DataRequestExpireSI8Data(
              si8RequestID || ""
              // { ...newPayload }
            )
          );
        } else {
          // return await handleRequest(
          //   Si8DataRequestService.postCoreApiApiAppSI8DataRequestSi8DataSearchRequest(
          //     {
          //       ...newPayload,
          //       searchKeyword: searchKeywords ?? "",
          //       sortOrder: order,
          //       sorting: sorting ? sorting : "",
          //       skipCount:
          //         page && resultsPerPage && (page - 1) * resultsPerPage,
          //       maxResultCount: resultsPerPage,
          //     }
          //   )
          // );
          return await handleRequest(
            (newPayload?.dataCompleted ||
              viewHistoryRef.current ||
              isPaginationOrSortingChanged) &&
              !generateDataRef.current
              ? Si8DataRequestService.getCoreApiApiAppSi8DataRequestPagedAndSortedSi8Data(
                  newPayload?.si8DataRequestId,
                  searchKeywords || undefined,
                  order || undefined,
                  sorting || undefined,
                  page && resultsPerPage
                    ? (page - 1) * resultsPerPage
                    : undefined,
                  resultsPerPage || undefined
                )
              : Si8DataRequestService.postCoreApiApiAppSi8DataRequestSi8DataSearchRequest(
                  {
                    ...newPayload,
                    searchKeyword: searchKeywords ?? "",
                    sortOrder: order,
                    sorting: sorting ? sorting : "",
                    skipCount:
                      page && resultsPerPage && (page - 1) * resultsPerPage,
                    maxResultCount: resultsPerPage,
                  }
                )
          );
        }
      },
      {
        onSuccess: async (response: any | undefined, payload) => {
          viewHistoryRef.current = false;
          if (
            response?.data?.si8DataRequestId &&
            payload?.generateGrowthOpportunities &&
            generateDataRef.current
          ) {
            const dataPayload = payload?.growthInformations;
            growdthDataGenerate({
              si8DataRequestId: response?.data?.si8DataRequestId,
              ...dataPayload,
            });
          }
          generateDataRef.current = false;
          setTableGenerated(true);
          const newdata = response && response?.data?.si8DataResults;
          const finalData = newdata?.map((d) => {
            const data = structuredClone(d);
            delete data.strategicImperativeId;
            delete data.strategicImperativeSystemName;
            delete data.durationCurveId;
            delete data.durationCurveSystemName;
            delete data.potentialImpactOnRevenueId;
            delete data.potentialImpactOnRevenueSystemName;
            delete data.strategicImperativeOrderNumber;
            delete data.isEdited;
            delete data.lastModificationTime;
            delete data.lastModifiedByFullName;
            delete data.lastModifierId;
            delete data.version;
            delete data.id;
            const newDataFormat = {
              "Strategic Imperative Category": data.strategicImperative,
              "Strategic Imperative": data.event,
              "Impact Score (%)": data.impactScore,
              "Impact Starts During": data.impactStartYear,
              "Duration of Impact (years)": data.impactDuration,
              "Duration Curve": data.durationCurve,
              "Financial Impact (%)": data.potentialImpactOnRevenue,
              Details: data.siDetail,
              Source: data.sourceName,
            };

            return newDataFormat;
          });

          if (response != null) {
            setIsLoading(false);
            if (exportBtn === true) {
              setExportData(finalData);
            } else if (
              response?.data?.si8DataProcessStageSystemName === "completed"
            ) {
              setButtonRest(false);
              setSi8DataResponse(response);
            } else if (
              response?.data?.si8DataProcessStageSystemName === "canceled"
            ) {
              setButtonRest(false);
              setSi8DataResponse(response);
            } else if (
              response?.data?.si8DataProcessStageSystemName === "failed"
            ) {
              setSi8DataResponse(response);
              setButtonRest(false);
            } else {
              setSi8DataResponse(response);

              const id =
                isExpire === true
                  ? response?.si8DataRequestId
                  : response?.data?.si8DataRequestId;
              // const GrowthopportunitiesId = response?.data?.si8DataRequestId;
              // GrowthopportunitiesId &&
              //   dispatch(setGrowthOpportunitiesId(GrowthopportunitiesId));
              // GrowthopportunitiesId &&
              //   dispatch(setGrowthOpportunitiesStatus(true));
              // console.log(GrowthopportunitiesId);
              const interval = setInterval(() => {
                handleRequest(
                  Si8DataRequestService.getCoreApiApiAppSi8DataRequestSI8DataRequestStatus(
                    id
                  )
                    .then(async (res) => {
                      setRecursiveResponse(res);
                      setIsExpire(false);
                      if (
                        res.data?.si8DataProcessStageSystemName === "completed"
                      ) {
                        setIsExpire(false);
                        generateSi8Data({
                          ...payload,
                          si8DataRequestId: res.data?.si8DataRequestId,
                          practiceAreaName: payload?.practiceAreaName,
                          customTransformationalJourney:
                            payload?.customTransformationalJourney,
                          description: payload?.description,
                          regionName: payload?.regionName,
                          dataCompleted: true,
                        });
                        clearInterval(interval);
                      } else if (
                        res?.data?.si8DataProcessStageSystemName === "canceled"
                      ) {
                        clearInterval(interval);
                        dispatch(removeGrowthOpportunities());
                      } else if (
                        res?.data?.si8DataProcessStageSystemName === "failed"
                      ) {
                        await setButtonRest(false);
                        await setFilterData(AiGenerateFilterInitial);
                        await clearInterval(interval);
                        dispatch(removeGrowthOpportunities());
                      }
                    })
                    .catch((error) => {
                      clearInterval(interval);
                      console.log(error);
                    })
                    .finally(() => {
                      setIsExpire(false);
                    })
                );
              }, 5000);
            }
          }
        },
        onError: (err) => {
          console.log("error", err);
          setIsExpire(false);
          setButtonRest(false);
        },
      }
    );
  };

  const handleDownloadFile = (
    fileName: string,
    fileContent?: string | null
  ): void => {
    const aElement = document.createElement("a");
    const downloadLink =
      `data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,` +
      fileContent;
    aElement.setAttribute("download", fileName);
    aElement.setAttribute("href", downloadLink);
    aElement.click();
  };
  const { mutate: generateSi8Data, isLoading: postDataIsLoading } =
    usePostSi8DataList(
      searchKeyword,
      resultsPerPage,
      page,
      isExpire,
      sorting,
      order
    );

  const { mutate: excelExport } = useGetExcelExport(
    handleRequest,
    handleDownloadFile
  );
  const { mutate: postCancelSi8Data } = usePostCancelSi8DataRequest(
    handleRequest,
    setSi8DataResponse,
    setButtonRest
  );

  //Generate data for Si8

  // GENERATE SI8 data
  const generateData = (values: any): void => {
    setToggle(1);
    setGeneratedValues(values);
    setPostPayload({
      ...values,
      practiceAreaName: values?.practiceAreaName,
      customTransformationalJourney: values?.customTransformationalJourney,
      description: values?.description,
      regionName: values?.regionName,
      additionalSources: values?.additionalSources,
      new: false,
    });
    generateSi8Data({
      ...values,
      practiceAreaName: values?.practiceAreaName,
      customTransformationalJourney: values?.customTransformationalJourney,
      description: values?.description,
      regionName: values?.regionName,
      additionalSources: values?.additionalSources,
      new: false,
    });
    setPage(1);
    setFilterGeneratedData(values);
    startTimer();
  };
  // EXPIRE SI8 data expire is also handle by usePostSi8DataList (generateData)
  const handleExpireData = async () => {
    await startTimer();
    await setIsExpire(true);
    await generateSi8Data(postPayload);
    dispatch(setUpdateFlag(true));
  };

  //CANCEL si8 data

  const handleCancelSi8Data = (payload: any) => {
    postCancelSi8Data(payload);
  };
  const resetSearch = async () => {
    await setSearchKeyword("");
    await generateData(postPayload);
  };

  useEffect(() => {
    if (tableData) {
      setData(tableList);
    }
  }, [tableList]);

  useEffect(() => {
    if (sorting || order) {
      generateSi8Data(postPayload);
    }
  }, [sorting, order]);
  useEffect(() => {}, [order]);
  useEffect(() => {
    if (searchKeyword !== null) {
      generateSi8Data(postPayload);
    }
  }, [searchKeyword]);

  return (
    <div>
      <>
        {popupdata && popupdata.length ? (
          <CustomModal
            title={"Notice"}
            show={showCarouselModal}
            onHide={() => {
              recordedIds && notifStatusChange(recordedIds);
            }}
          >
            <UpdateCarousel
              rowData={popupdata}
              setRecordedIds={setRecordedIds}
              closeCarouselModal={closeCarouselModal}
              notifStatusChange={notifStatusChange}
            />
          </CustomModal>
        ) : (
          ""
        )}
        {showFailModal && (
          <CustomModal
            show={showFailModal}
            size="sm"
            className="data-generation"
            onHide={closeFailModal}
          >
            <div className="text-center">
              <h2>Data Generation failed!</h2>
            </div>
            <div className="text-center mt-3">
              <button
                onClick={closeFailModal}
                style={stylePrimary}
                type="button"
              >
                Continue
              </button>
            </div>
          </CustomModal>
        )}
        <Container>
          <div className="app_layout flex flex-row max-h-screen overflow-hidden">
            <Button
              variant={"primary"}
              className="fixed top-[70px] left-0 rounded-[0_6px_6px_0] p-3 px-2 shadow z-[98] opacity-50 hover:opacity-100"
              onClick={() => setActive(!isActive)}
            >
              <SlidersHorizontal size={18} />
            </Button>
            <div
              className={cn(
                "left h-screen md:h-[calc(100vh_-_56px)] bg-primary transition-all duration-300  rounded-tr-lg rounded-br-lg overflow-hidden fixed z-[99] md:relative",
                {
                  "w-screen translate-x-[-100vw] md:translate-0 md:w-0":
                    isActive,
                  "w-screen translate-0 md:w-[305px]": !isActive,
                }
              )}
            >
              <Sidebar
                tableInfo={tableInfo}
                generateData={generateData}
                si8DataResponse={si8DataResponse}
                handleCancelSi8Data={handleCancelSi8Data}
                isLoading={isLoading}
                setButtonRest={setButtonRest}
                buttonRest={buttonRest}
                filterData={filterData}
                setFilterData={setFilterData}
                isActive={isActive}
                setActive={setActive}
                generateDataRef={generateDataRef}
                // startTimer={startTimer}
              />
            </div>
            <div className="right flex-1 max-h-screen overflow-y-auto">
              <FilterProvider
                value={{
                  showCancelModal,
                  toggle,
                  setToggle,
                  setActive,
                  showFailModal,
                  closeFailModal,
                  si8DataResponse,
                  showTimer,
                  formatTime,
                  seconds,
                  setShowCancelModal,
                  showModal,
                  closeModal,
                  stylePrimary,
                  handleCancelSi8Data,
                  data,
                  postPayload,
                  excelExport,
                  addRow,
                  addNew,
                  handleRowSubmit,
                  rowData,
                  handleExpireData,
                  userAuthList,
                  setSearchKeyword,
                  setExportBtn,
                  handleExport,
                  csvLinkRef,
                  exportData,
                  setIsExpire,
                  isExpire,
                  resetSearch,
                  filterGeneratedData,
                  setPage,
                  setTableInfo,
                  setRowData,
                  sortBy,
                  sort,
                  updateHandler,
                  deleteHandler,
                  setAddNew,
                  setSorting,
                  sorting,
                  setOrder,
                  edited,
                  order,
                  generateSi8Data,
                  tableGenerated,
                  setShowHistoryModal,
                  generateData,
                  setButtonRest,
                  setFilterData,
                  setFilterGeneratedData,
                  setData,
                  setSi8DataResponse,
                  setStart,
                  page,
                  filterData,
                  resultsPerPage,
                  isActive,
                  viewHistoryRef,
                  setTableGenerated,
                }}
              >
                <Navbar />
                <div className="box-holder mt-4 px-6 mb-[80px] min-h-[calc(100vh_-_170px)]">
                  <nav className="outlet-flex-container">
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "nav-bar-link active" : "nav-bar-link"
                      }
                      to="/transformation"
                    >
                      Transformation Model
                    </NavLink>
                    <NavLink
                      className={({ isActive }) =>
                        isActive ? "nav-bar-link active" : "nav-bar-link"
                      }
                      to="/growth-opportunity"
                    >
                      Growth Opportunities
                    </NavLink>
                  </nav>
                  <Outlet />
                </div>
                <Footer />
              </FilterProvider>
            </div>
          </div>
        </Container>
      </>
    </div>
  );
};

export default Home;

import * as Yup from "yup";
export const alphanumericaAndSpecialChar =
  /^(?!\s*$)(?!.*['\-_&\[\]().:;'"/$, \n’ʹʺʻʼʽˮ%£]{4})[-A-Za-z0-9_$&\[\]().:;'"/$, \n’ʹʺʻʼʽˮ%£]+(?:\s,\s?[-A-Za-z0-9_$&\[\]().:;'"/$, \n’ʹʺʻʼʽˮ%£]+)*$/;

export const alphanumericaAndSpecialCharGrowth =
  /^[-A-Za-z0-9_$&\[\]().:;'"/$, \n'ʹʺʻʼʽˮ%£ ]+$/;

export const descriptionRegex = /^[0-9A-Za-z&\s\[\]\(\)\.\:\;\,\'\/\-\$\%]*$/;
const atleastoneAlphanumbericWithComma = /^[A-Za-z]+$/;
export const textWithoutSpace = /^\s*\S[\s\S]*$/;
export const urlValidation =
  /^(https?:\/\/)?([a-zA-Z0-9-]+(\.[a-zA-Z0-9-]+)+)(:\d+)?(\/.*)?$/;

interface validationObject {
  strategicImperative?: string;
  event?: string;
  impactScore?: string;
  impactStartYear?: string;
  impactDuration?: string;
  durationCurve?: string;
  potentialImpactOnRevenue?: string;
  siDetail?: string;
}

export const SIDEBAR_VALIDATION = (isActiveChecked) =>
  Yup.object().shape({
    Industry: Yup.string()
      .required("This is a required field.")
      .matches(alphanumericaAndSpecialChar, "Please enter valid Industry."),
    SubSector: Yup.string()
      .required("This is a required field.")
      .matches(alphanumericaAndSpecialChar, "Please enter valid Sub-Sector."),
    keywordsToAvoid: Yup.string().matches(
      alphanumericaAndSpecialChar,
      "Please enter valid Keywords."
    ),
    regionName: Yup.string()
      .required("This is a required field.")
      .matches(alphanumericaAndSpecialChar, "Please enter valid Region."),
    description: Yup.string()
      .nullable()
      .matches(alphanumericaAndSpecialChar, "Please enter valid Description.")
      .matches(
        textWithoutSpace,
        "This field must not contain blank spaces only."
      )
      .max(5000, "Exceeded maximum length of 5000 characters"),
    additionalSources: Yup.string().nullable(),
    growthPerspective: Yup.string().when("generateGrowthOpportunities", {
      is: true,
      then: (schema) => schema.required("Growth Perspective is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
    growthProcess: Yup.string().when("generateGrowthOpportunities", {
      is: true,
      then: (schema) => schema.required("Growth Process is required"),
      otherwise: (schema) => schema.notRequired(),
    }),
  });

export const USER_VALIDATION_SCHEMA = Yup.object().shape({
  firstName: Yup.string()
    .required("This is a required field.")
    .matches(/^[a-zA-Z0-9\s'-]+$/, "Invalid Data"),
  lastName: Yup.string()
    .required("This is a required field.")
    .matches(/^[a-zA-Z0-9\s'-]+$/, "Invalid Data"),
  email: Yup.string()
    .email("Invalid email")
    .required("This is a required field."),
});

export const validation = (formData, setErrors) => {
  const validationErrors: validationObject = {};
  // const regex = /^[a-zA-Z0-9\s'-.&()</>*\n ]+$/;
  const detailRegex = /^[-a-zA-Z0-9\s_&\[\]().'",</>*\n=\s:; ]+$/;
  if (!formData?.strategicImperative?.trim()) {
    validationErrors.strategicImperative = "Required Field";
  } else if (!alphanumericaAndSpecialChar.test(formData?.strategicImperative)) {
    validationErrors.strategicImperative = "Invalid data";
  } else if (formData.strategicImperative.length > 500) {
    validationErrors.strategicImperative = `Exceeds maximum length of 450 characters`;
  }
  if (!formData?.event?.trim()) {
    validationErrors.event = "Required Field";
  } else if (!alphanumericaAndSpecialChar.test(formData?.event)) {
    validationErrors.event = "Invalid data";
  } else if (formData.event.length > 500) {
    validationErrors.event = `Exceeds maximum length of 450 characters`;
  }
  if (
    formData?.impactScore === undefined ||
    formData?.impactScore === null ||
    formData?.impactScore === ""
  ) {
    validationErrors.impactScore = "Required Field";
  } else if (isNaN(formData?.impactScore)) {
    validationErrors.impactScore = "Must be a number";
  } else if (
    formData?.impactScore.length >= 3 &&
    formData?.impactScore !== "100"
  ) {
    if (!/^\d{2}$/.test(formData.impactScore)) {
      validationErrors.impactScore = "Must be less than or equal to 100";
    }
  } else {
    if (!alphanumericaAndSpecialChar.test(formData?.impactScore)) {
      validationErrors.impactScore = "Invalid data";
    }
  }

  if (formData?.impactStartYear === "") {
    validationErrors.impactStartYear = "Required Field ";
  } else if (isNaN(formData?.impactStartYear)) {
    validationErrors.impactStartYear = "Must be a number";
  } else if (formData?.impactStartYear.length !== 4) {
    if (!/^\d{4}$/.test(formData?.impactStartYear)) {
      validationErrors.impactStartYear = "Enter a 4 digit year ";
    }
  }
  // else {
  //   if (!alphanumericaAndSpecialChar.test(formData?.impactStartYear)) {
  //     validationErrors.impactStartYear = "Invalid data";
  //   }
  // }

  if (
    formData?.impactDuration === undefined ||
    formData?.impactDuration === null ||
    formData?.impactDuration === ""
  ) {
    validationErrors.impactDuration = "Required Field";
  } else if (isNaN(formData?.impactDuration)) {
    validationErrors.impactDuration = "Must be a number";
  } else if (formData.impactDuration.length > 2) {
    if (!/^\d{2}$/.test(formData?.impactDuration)) {
      validationErrors.impactDuration = "Enter a 2 digit number ";
    }
  } else {
    if (!alphanumericaAndSpecialChar.test(formData?.impactDuration)) {
      validationErrors.impactDuration = "Invalid data";
    }
  }
  if (!formData?.durationCurve?.trim()) {
    validationErrors.durationCurve = "Required Field";
  } else {
    if (!atleastoneAlphanumbericWithComma.test(formData?.durationCurve)) {
      validationErrors.durationCurve = "Only Alphabets are allowed";
    }
  }

  if (!formData?.potentialImpactOnRevenue?.trim()) {
    validationErrors.potentialImpactOnRevenue = "Required Field";
  } else if (isNaN(formData?.potentialImpactOnRevenue)) {
    validationErrors.potentialImpactOnRevenue = "Must be a number";
  } else if (
    formData?.potentialImpactOnRevenue.length >= 3 &&
    formData?.potentialImpactOnRevenue !== "100"
  ) {
    if (!/^\d{2}$/.test(formData.potentialImpactOnRevenue)) {
      validationErrors.potentialImpactOnRevenue =
        "Must be less than or equal to 100 ";
    }
  } else {
    if (!alphanumericaAndSpecialChar.test(formData?.potentialImpactOnRevenue)) {
      validationErrors.potentialImpactOnRevenue = "Invalid data";
    }
  }
  if (!formData?.siDetail?.trim()) {
    validationErrors.siDetail = "Required Field";
  } else if (formData.siDetail.length > 1000) {
    validationErrors.siDetail = `Exceeds maximum length of 1000 characters`;
  } else if (!detailRegex.test(formData?.siDetail)) {
    validationErrors.siDetail = "Invalid data";
  }
  setErrors(validationErrors);
  return validationErrors;
};

export const FEEDBACK_VALIDATION = Yup.object().shape({
  requester: Yup.string()
    .required("This is a required field.")
    .matches(alphanumericaAndSpecialChar, "Please enter valid requester.")
    .max(150, "Exceeded maximum length of 150 characters"),
  subject: Yup.string()
    .required("This is a required field.")
    .matches(alphanumericaAndSpecialChar, "Please enter valid subject.")
    .max(150, "Exceeded maximum length of 150 characters"),
  //   feedbackTicketTypeId: Yup.string().required("Ticket Type is required"),
  // feedbackPriorityLevelId: Yup.string().required("Urgency is required"),
  // region: Yup.string()
  //   .required("This is a required field.")
  //   .matches(alphanumericaAndSpecialChar, "Please enter valid Region.")
  //   .max(150, "Exceeded maximum length of 150 characters"),
  // requesterDepartment: Yup.string()
  //   .required("This is a required field.")
  //   .matches(alphanumericaAndSpecialChar, "Please enter valid Department.")
  //   .max(150, "Exceeded maximum length of 150 characters"),
  description: Yup.string()
    .required("This is a required field.")
    .matches(alphanumericaAndSpecialChar, "Please enter valid Description.")
    .max(5000, "Exceeded maximum length of 5000 characters"),
  attachments: Yup.array()
    .max(3, "You can only upload up to 3 files")
    .test("fileMimeType", "Incorrect file type Uploaded.", (value) => {
      if (!value) {
        return true; // Pass validation if no files are uploaded
      }
      const allowedFileTypes = [
        "image/jpeg",
        "image/png",
        "video/mp4",
        "image/jpg",
      ];
      return value.every((file) => allowedFileTypes.includes(file?.type));
    })
    .test(
      "fileSize",
      "File size is too large. Maximum size is 15MB",
      (value) => {
        if (!value) {
          return true; // Pass validation if no files are uploaded
        }
        const maxFileSize = 15 * 1024 * 1024;
        return !value.some((file) => file.size > maxFileSize);
      }
    )
    .nullable(),
});
export const UPDATE_VALIDATION = Yup.object().shape({
  title: Yup.string()
    .transform((value) =>
      typeof value === "string" ? value.replace(/\s+/g, " ").trim() : value
    )
    .required("This is a required field.")
    .matches(alphanumericaAndSpecialChar, "Please enter valid title.")
    .max(150, "Exceeded maximum length of 150 characters"),
  version: Yup.string()
    .transform((value) => value.replace(/\s+/g, " ").trim())
    .required("This is a required field.")
    .matches(alphanumericaAndSpecialChar, "Please enter valid version.")
    .max(150, "Exceeded maximum length of 150 characters"),
  description: Yup.string()
    .transform((value) => value.replace(/\s+/g, " ").trim())
    .required("This is a required field.")
    .matches(alphanumericaAndSpecialChar, "Please enter valid Description.")
    .max(5000, "Exceeded maximum length of 5000 characters"),
});

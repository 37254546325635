import { AreaChartPreview } from "@/components/chart-area-stacked";
import { BarChartPreview } from "@/components/chart-bar-default";
import { LineCharPreview } from "@/components/chart-line-linear";
import { PieChartPreview } from "@/components/chart-pie-donut-text";
import { RadarChartPreview } from "@/components/chart-radar-default";
import { Button } from "@/components/ui/button";
import { Card } from "@/components/ui/card";
import { ToggleGroup, ToggleGroupItem } from "@/components/ui/toggle-group";
import { cn } from "@/lib/utils";
import React, { useState } from "react";

const filterOption = [
  { label: "Today", value: "today" },
  { label: "Weekly", value: "weekly" },
  { label: "Monthly", value: "monthly" },
  { label: "Yearly", value: "yearly" },
];

const totalOverviews = [
  {
    label: "Total Users",
    value: "24,532",
    text: "Out of which 107 are admins",
  },
  {
    label: "Total Strategic Imperatives",
    value: "410",
    text: "10% less than previous month",
  },
  {
    label: "Top 10 Strategic Imperative Generated / Total Docx Exported",
    value: "568 / 36",
    // text: "Out of which 123 are admins",
  },
  {
    label: "Total Sub-Segment Generated",
    value: "97",
    text: "12% more than previous month",
  },
];

export default function DashboardPage() {
  const [selectedFilter, setSelectedFilter] = useState(filterOption[2].value);

  return (
    <div>
      <div className="mb-3 flex items-center justify-between">
        <div font-semibold text-xl text-black>
          {" "}
          Dashboard Overview
        </div>
        {/* <Button variant="primary">Export</Button> */}
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-3">
        {totalOverviews.map((item: (typeof totalOverviews)[0]) => {
          return (
            <Card className="p-4 flex flex-col gap-0">
              <div className="font-semibold text-sm mb-2">{item.label}</div>
              <div className="text-2xl font-bold mb-[5px]">{item.value}</div>
              <div className="text-[#9D9D9D] text-xs">{item.text}</div>
            </Card>
          );
        })}
      </div>
      <div className="flex items-center justify-end my-8">
        <ToggleGroup
          type="single"
          className=" rounded-none p-1  rounded-md border border-[#E4E4E7]"
          defaultValue={selectedFilter}
          onValueChange={(value: string) => setSelectedFilter(value)}
        >
          {filterOption.map((item) => {
            return (
              <ToggleGroupItem
                value={item.value}
                className={cn(
                  " cursor-pointer px-4 !py-1 font-medium text-sm leading-none hover:!bg-none",
                  {
                    "!bg-[#172D55] !text-white !rounded-sm":
                      item.value === selectedFilter,
                  }
                )}
              >
                {item.label}
              </ToggleGroupItem>
            );
          })}
        </ToggleGroup>
      </div>

      <div>{selectedFilter}</div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  grid-row-2 gap-3">
        <BarChartPreview />
        <LineCharPreview />
        <AreaChartPreview />
        <RadarChartPreview />

        <PieChartPreview />
      </div>
    </div>
  );
}

/* eslint-disable camelcase */
// import { isEmpty } from 'lodash';
import { type UseMutationResult, useMutation } from "react-query";
import {
  FSi8_Dtos_SI8DataRequests_SI8DataResponseDto,
  Si8DataRequestService,
} from "../../services/frost-si8-services";
import { toast } from "react-toastify";
// import { Si8TableData } from "../../utils/common";

const usePostAddEditSi8Data = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>,
  id?: string,
  setSi8DataResponse?: any,
  si8DataResponse?: any,
  setrowId?: any,
  setEdited?: any,
  data?: any
): UseMutationResult<
  FSi8_Dtos_SI8DataRequests_SI8DataResponseDto | undefined,
  unknown,
  any,
  unknown
> => {
  return useMutation(
    async (payload: any) => {
      const newPayload = {
        ...payload,
        si8DataRequestId: si8DataResponse?.data?.si8DataRequestId,
      };

      if (payload?.id != null && payload?.id !== "") {
        return await handleRequest(
          Si8DataRequestService.putCoreApiApiAppSi8DataRequestSI8Data(
            payload?.id,
            { ...newPayload }
          )
        );
      }
      return await handleRequest(
        Si8DataRequestService.postCoreApiApiAppSi8DataRequestSI8Data(newPayload)
      );
    },
    {
      onSuccess: async (
        response: FSi8_Dtos_SI8DataRequests_SI8DataResponseDto | undefined,
        payload?: any
      ) => {
        if (response != null) {
          // await Si8TableData(handleRequest, setSi8DataResponse, 1000);
          payload?.id === "" && setrowId(response.id);
          setEdited(response);

          si8DataResponse?.data?.si8DataProcessStageSystemName ===
            "completed" &&
            (await toast.success(
              payload?.id != null && payload?.id !== ""
                ? "Item has been updated."
                : "New item has been added.",
              {
                position: "top-center",
                autoClose: 5000,
                closeOnClick: true,
                hideProgressBar: false,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "light",
              }
            ));
          return true;
        }
      },
      onError: (err) => {
        console.log("error", err);
        data.pop();
      },
    }
  );
};

export default usePostAddEditSi8Data;

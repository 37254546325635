import React, { useEffect, useState } from "react";
import { useGetCurrentProfile } from "../../hooks";
import useFrostSi8Api from "../../hooks/useFrostSi8Api";
import { StyledDropdown } from "./navbar.styled";
import AuthService from "../../services/authService";
import { useDispatch, useSelector } from "react-redux";
import { selectAuthRoutesList } from "../../pages/configuration/routeSlice";
import { setPdfAnalystName } from "../../store/slices/PdfExportSlice";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";
import { ShieldUser, User } from "lucide-react";

const UserBox: React.FC = () => {
  const authService = new AuthService();
  const { handleRequest } = useFrostSi8Api();
  const { data } = useGetCurrentProfile(handleRequest);
  const userAuthList = useSelector(selectAuthRoutesList);
  const dispatch = useDispatch();

  const [active, setActive] = useState<boolean>(false);

  const handleOpen = () => {
    setActive(!active);
  };

  useEffect(() => {
    if (data?.name || data?.surname) {
      dispatch(
        setPdfAnalystName(`${data?.name ?? ""} ${data?.surname ?? ""}`?.trim())
      );
    }
  }, [data]);
  const Logo = () => (
    <>
      {userAuthList?.currentUser?.roles[0] === "admin" ? (
        <svg
          className="w-6 h-6"
          fill="#686583"
          xmlns="http://www.w3.org/2000/svg"
          width="48"
          height="24"
          viewBox="0 0 48 24"
        >
          <path
            fill="#172d55"
            d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 0 1 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z"
          />
          <path
            fill="#3694ab"
            transform="scale(0.4) translate(35, 30)"
            d="M12 1L3 5v6c0 5.55 3.84 10.74 9 12c5.16-1.26 9-6.45 9-12V5l-9-4z"
          />
          <path
            fill="white"
            transform="scale(0.4) translate(35.5, 31)"
            d="M10 14.17L4.31 8.59 3 10l7 7 10-10-1.41-1.41z"
          />
        </svg>
      ) : (
        <svg
          className="w-6 h-6"
          fill="#686583"
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
        >
          <path d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zM7.07 18.28c.43-.9 3.05-1.78 4.93-1.78s4.51.88 4.93 1.78C15.57 19.36 13.86 20 12 20s-3.57-.64-4.93-1.72zm11.29-1.45c-1.43-1.74-4.9-2.33-6.36-2.33s-4.93.59-6.36 2.33A7.95 7.95 0 0 1 4 12c0-4.41 3.59-8 8-8s8 3.59 8 8c0 1.82-.62 3.49-1.64 4.83zM12 6c-1.94 0-3.5 1.56-3.5 3.5S10.06 13 12 13s3.5-1.56 3.5-3.5S13.94 6 12 6zm0 5c-.83 0-1.5-.67-1.5-1.5S11.17 8 12 8s1.5.67 1.5 1.5S12.83 11 12 11z" />
        </svg>
      )}
    </>
  );

  return (
    <>
      {/* <StyledDropdown
        onBlur={() => setActive(false)}
        className={`user-block ${active ? "active" : ""}`}
      >
        <button className="user-opener" onClick={handleOpen}>
          <span className="username">
            Welcome,{" "}
            {`${data?.name ? data?.name : ""} ${
              data?.surname ? data?.surname : ""
            } `}
            <Logo />
          </span>
        </button>
        <ul className="dropdown">
          <li>
            <button
              onClick={() => {
                void authService.logout();
              }}
            >
              Logout
            </button>
          </li>
        </ul>
      </StyledDropdown> */}
      <DropdownMenu>
        <DropdownMenuTrigger className="cursor-pointer">
          <div className="flex items-center gap-2 cursor-pointer">
            <div className="hidden md:block">
              Welcome,{" "}
              {`${data?.name ? data?.name : ""} ${
                data?.surname ? data?.surname : ""
              } `}
            </div>
            <div className="size-[40px] rounded-full bg-gray-200 grid place-items-center">
              {userAuthList?.currentUser?.roles[0] === "admin" ? (
                <ShieldUser size={30} color="#3694ab" />
              ) : (
                <User size={30} color="#3694ab" />
              )}
            </div>
          </div>
        </DropdownMenuTrigger>
        <DropdownMenuContent className="z-50">
          <DropdownMenuLabel>My Account</DropdownMenuLabel>
          <DropdownMenuSeparator />
          <DropdownMenuItem
            className="!text-red-400 cursor-pointer"
            onClick={() => {
              void authService.logout();
            }}
          >
            Logout
          </DropdownMenuItem>
        </DropdownMenuContent>
      </DropdownMenu>
    </>
  );
};

export default UserBox;

import styled from "styled-components";
import { colors } from "../../constants/themeConstants";

export const SidebarWrapper = styled.div`
  // @media (max-width: 992px) {
  //   transform: translateX(-100%);
  // }
  .sidebar-body {
    font-size: 12px;
    padding: 12px 18px 12px;
    &:hover {
      &::-webkit-scrollbar-thumb {
        background: #888 !important;
      }
    }
    &::-webkit-scrollbar {
      background-color: transparent !important;
      width: 8px !important;
    }
    &::-webkit-scrollbar-thumb {
      background: transparent !important;
    }
    &::-webkit-scrollbar-thumb:hover {
      cursor: pointer;
    }
  }

  input[type="text"] {
    &::-webkit-input-placeholder {
      color: gray;
    }
  }

  .sidebar {
    & ::-webkit-scrollbar-track {
      background-color: transparent;
    }
    & ::-webkit-scrollbar-thumb {
      background: transparent;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: transparent;
    }
  }

  /* .sidebar ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 6px rgba(48, 85, 23, 0.3);
  } */

  /* .sidebar ::-webkit-scrollbar-thumb {
    background: #172d55;
  } */

  .box ::-webkit-scrollbar {
    height: 8px;
    border-radius: 4px;
  }

  .box ::-webkit-scrollbar-track {
    /* background: #fafafa; */
    /* box-shadow: 0px -1px 0px 0px #f0f0f0 inset, 0px 1px 0px 0px #e8e8e8 inset; */
  }

  .box ::-webkit-scrollbar-thumb {
    background: #c1d9ec;
    border-radius: 4px;
  }

  .sidebar {
    border-radius: 2px;
    font-size: 14px;
    line-height: 1.65;
    z-index: 99;
    transform: translateX(0);
    transition: transform 0.3s ease-in-out;
    height: 100%;
    /* width: 280px; */
    .ant-form-item-label {
      label {
        color: #fff;
      }
    }
    .ant-tooltip {
      z-index: 99999;
    }
    .anticon,
    .ant-form-item-control-input-content,
    .ant-checkbox-wrapper {
      color: #fff;
      textarea {
        &::placeholder {
          color: rgba(0, 0, 0, 0.6);
        }
      }
    }
    .ant-input-disabled {
      opacity: 0.5;
      background-color: #fff;
    }
    .ant-checkbox-wrapper-checked {
      .ant-checkbox-inner {
        background-color: #f7913d !important;
        border-color: #f7913d !important;
      }
    }
    .ant-checkbox-wrapper-disabled {
      opacity: 0.5;
      span {
        color: #fff !important;
      }
    }

    .ant-checkbox-inner {
      background-color: #fff;
      border-color: #fff;
    }
  }

  .journey .container {
    max-width: none;
    width: 100%;
  }

  .checkbox-label {
    color: ${colors.secondary};
    display: inline-block;
    font-size: 11px;
    font-weight: 400;
    letter-spacing: 0.3px;
    line-height: 1.0909;
    line-height: 1.625;
    margin: 0 0 14px;
    vertical-align: top;
  }
  .checkbox {
    position: relative;
    padding-left: 24px;
    margin-left: 0;
    margin-right: 0;
    color: #000;
    margin-bottom: 0;
  }

  .checkbox input {
    opacity: 0;
    visibility: hidden;
    width: 0;
    height: 0;
    position: absolute;
    left: 0;
    top: 0;
  }

  .checkbox .slide {
    position: absolute;
    left: 0;
    top: 0px;
    width: 16px;
    height: 16px;
    border-radius: 4px;
    border: 1px solid;
    background: #fff;
  }

  .checkbox input:checked ~ .slide:before {
    opacity: 1;
  }
  .checkbox input:disabled ~ .slide {
    background: #eeeeee;
  }

  .checkbox .slide:before {
    content: "";
    background-image: url("data:image/svg+xml,%3Csvg preserveAspectRatio='none' version='1.1' xmlns='http://www.w3.org/2000/svg' width='1024' height='1024' viewBox='0 0 1024 1024'%3E%3Ctitle%3E%3C/title%3E%3Cg id='icomoon-ignore'%3E%3C/g%3E%3Cpath fill='%23000' d='M336 869.333l-314.667-352c-32-32-26.667-85.333 5.333-112 32-32 85.333-26.667 112 5.333l208 234.667 544-464c32-26.667 85.333-26.667 112 10.667 26.667 32 26.667 85.333-10.667 112l-656 565.333z'%3E%3C/path%3E%3C/svg%3E%0A");
    background-size: 10px 10px;
    width: 10px;
    height: 10px;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    opacity: 0;
    transition: opacity 0.3s ease-in-out;
  }

  .btn {
    width: 100%;
    display: flex;
    justify-content: center;
  }

  .form-group {
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .form-group:last-child {
    border: none;
  }

  .form-group input[type="search"] {
    display: block;
    width: 100%;
    height: 43px;
    padding: 3px 18px;
    font-family: "Nunito Sans";

    border: 1px solid #b8bbc2;
    background: #fff;
    color: #dbdbdb;
  }

  .form-group input[type="search"]::-webkit-input-placeholder {
    font-size: 12px !important;
    color: #dbdbdb;
  }

  .form-group input[type="text"]::-webkit-input-placeholder {
    font-size: 12px !important;
    color: #dbdbdb;
  }

  .form-group input[type="search"]::-ms-input-placeholder {
    font-size: 10px !important;
    color: #dbdbdb;
  }

  .custom-label {
    font-size: 12px;
  }
  .dBVBsq {
    white-space: nowrap;
    color: #757575;
    font-weight: 600;
    font-size: 12px;
    margin-bottom: 3px;
    letter-spacing: 0;
  }

  .form-group
    input[type="search"].form-group
    input[type="search"]:-ms-input-placeholder {
    /* Internet Explorer */
    color: #dbdbdb;
  }

  ::placeholder {
    color: #dbdbdb;
  }

  .filter-opener {
    z-index: 99;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    background: #172d55;
    border-radius: 0 10px 10px 0;
    border: none;
    font-size: 16px;
    font-weight: 700;
    margin-bottom: 21px;
    padding: 8px 10px;
    transition: padding 0.35s cubic-bezier(0.3, 0.86, 0.36, 0.95),
      transform 0.3s cubic-bezier(0.3, 0.86, 0.36, 0.95);
    cursor: pointer;
    position: fixed;
    left: 0;
    top: 80px;
    width: 35px;
    height: 35px;
    transform: translateX(-110%);
  }

  .filter-opener svg {
    width: 30px;
  }

  .active.filter-opener {
    transform: translateX(0);
  }

  /* .sidebar-header {
    align-items: center;
    background-color: ${colors.secondary};
    color: #fff !important;
    display: flex;
    flex-wrap: wrap;
    font-family: "Nunito Sans";

    font-size: 16px;
    font-weight: 600;
    line-height: 20px;
    margin-bottom: 0;
    padding: 15px 27px;
    position: relative;
  } */
  /* 
  .sidebar-header svg {
    width: 30px;
    height: 30px;
  }

  .sidebar-txt {
    display: block;
    padding-left: 10px;
  } */

  .form-wrap .form-group {
    /* margin: 0; */
    padding: 0;
    border: none;
  }

  .form-wrap {
    border-bottom: 1px solid #e7e7e7;
    margin-bottom: 10px;
    padding-bottom: 10px;
  }

  .btn-close {
    background: none;
    border: none;
    height: 30px;
    padding: 0;
    position: absolute;
    right: 20px;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    width: 30px;
    cursor: pointer;
    opacity: 1;
    &:before {
      background: #fff;
      content: "";
      display: block;
      height: 2px;
      margin-left: auto;
      width: 13px;
    }
  }

  .btn-close svg {
    width: 20px;
    height: 20px;
  }
`;

import { cn } from "@/lib/utils";
import { LayoutDashboard, Mail, Menu, Settings, Users } from "lucide-react";

import { useEffect, useState } from "react";
import { Link, Outlet, useLocation } from "react-router-dom";
import Navbar from "@/components/navbar/navbar";

export default function SuperAdminLayout() {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { pathname } = useLocation();

  const sidebarActionHandler = () => {
    const screenWidth = window.innerWidth;
    if (screenWidth < 800) {
      setIsSidebarOpen(false);
    }
  };
  useEffect(() => {
    sidebarActionHandler();
  }, []);

  const AdminSidebarLink = ({ link, children }) => {
    return (
      <Link
        to={link}
        onClick={sidebarActionHandler}
        className={cn(
          "flex items-center gap-4 text-lg text-[#A7A7A7] font-medium",
          {
            "text-white": pathname === link,
          }
        )}
      >
        {children}
      </Link>
    );
  };
  return (
    <div className=" flex h-screen overflow-hidden ">
      <div
        className={cn(
          "left  bg-[#172C54] transition-all overflow-x-hidden duration-200 fixed w-full h-screen z-[99999] md:relative",
          {
            "md:w-[271px]": isSidebarOpen,
            "translate-x-[-100vw] md:translate-x-[0px] md:w-[76px]":
              !isSidebarOpen,
          }
        )}
      >
        <div className="w-full md:w-[271px] flex flex-col justify-between h-full">
          <div>
            <div className="top h-[80px] text-white  flex items-center gap-4  px-[30px]  ">
              <button
                type="button"
                onClick={() => setIsSidebarOpen(!isSidebarOpen)}
                className="cursor-pointer"
              >
                <Menu />
              </button>
              <div className="text-[20px] font-bold">Admin Panel</div>
            </div>
            <div className="text-white px-[30px] pt-6 space-y-4">
              <AdminSidebarLink link="/admin">
                <LayoutDashboard size={22} />
                Dashboard
              </AdminSidebarLink>
              <AdminSidebarLink link="/admin/user-management">
                <Users size={22} />
                User Management
              </AdminSidebarLink>
              <AdminSidebarLink link="/admin/user-management">
                <Settings size={22} />
                Settings
              </AdminSidebarLink>
            </div>
          </div>
          {isSidebarOpen && (
            <div className="px-[30px] ">
              <div className="text-white  pb-10 pt-4 text-sm flex flex-col border-t border-[#979797]">
                <div className="flex items-center gap-2 mb-2">
                  <Mail size={14} />
                  tm.support@frost.com
                </div>
                <div>© 2025 Frost & Sullivan</div>
                <div>All Rights Reserved</div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div className="right flex flex-col flex-1 overflow-auto bg-white ">
        <Navbar />
        <div className=" p-4 ">
          <Outlet />
          <div className="text-3xl grid grid-cols-3 gap-4 "></div>
        </div>
      </div>
    </div>
  );
}

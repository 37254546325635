import moment from "moment";
import { useState } from "react";
import { Link } from "react-router-dom";
import CustomModal from "../../components/modal";
import useCustomModal from "../../hooks/customHooks/useCusomModal";
import UpdateView from "./updateViewModal";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuTrigger,
} from "@/components/ui/dropdown-menu";
import { Button } from "@/components/ui/button";
import { Bell } from "lucide-react";
import { Badge } from "@/components/ui/badge";
import { ScrollArea } from "@/components/ui/scroll-area";

interface NotificationProps {
  markAsRead: any;
  badgeNotif: any;
  notifStatusChange: (args) => void;
  mutate: (args) => void;
}

const Notification = ({
  markAsRead,
  badgeNotif,
  notifStatusChange,
  mutate,
}: NotificationProps) => {
  const { showModal, openModal, closeModal } = useCustomModal();
  const [open, setOpen] = useState(false);
  const [notificationData, setNotificationData] = useState<any>("");

  const openNotification = (id: string | undefined) => {
    const notification = badgeNotif?.find(
      (item) => item?.id === id
    )?.description;
    openModal();
    setNotificationData(notification);
  };

  const markAllRead = (ids: any) => {
    if (ids.length > 0) {
      mutate(ids);
    }
  };

  const openNotif = (item) => {
    openNotification(item?.id);
    const notificationData = {
      notificationId: item.id,
      isSeen: !item.isSeen,
      isPopupOpened: item.isPopupOpened,
    };
    notifStatusChange([notificationData]);
    openModal();
  };

  const unreadCount = badgeNotif?.filter((item) => !item.read).length;

  return (
    <>
      <DropdownMenu
        open={open}
        onOpenChange={(value: boolean) => {
          setOpen(value);
        }}
      >
        <DropdownMenuTrigger>
          <Button
            variant="ghost"
            size="icon"
            className="relative p-2.5 md:p-3 cursor-pointer"
          >
            <Bell className="scale-[1.3]" color="#1582C5" />
            {unreadCount > 0 && (
              <>
                <Badge className="absolute -top-1 -right-1 size-5 rounded-full flex items-center justify-center p-0 text-xs bg-[#f28e36] text-white">
                  {unreadCount}
                </Badge>
              </>
            )}
          </Button>
        </DropdownMenuTrigger>
        <DropdownMenuContent align="end" className="w-80 z-50">
          <div className="flex items-center justify-between px-3 py-2 border-b">
            <h4 className="font-semibold">Notifications</h4>
            {unreadCount > 0 && (
              <Button
                variant="ghost"
                size="sm"
                onClick={() => markAllRead(markAsRead)}
                className="text-xs h-8 font-semibold cursor-pointer"
              >
                Mark All As Read
              </Button>
            )}
          </div>

          <div className="max-h-[200px] overflow-auto">
            {badgeNotif?.length > 0 ? (
              <div className="py-1">
                {badgeNotif?.map((notification) => (
                  <button
                    key={notification.id}
                    className={`w-full text-left px-3 py-3 hover:bg-muted/50 transition-colors cursor-pointer ${
                      !notification.read ? "bg-muted/20" : ""
                    }`}
                    onClick={() => openNotif(notification)}
                  >
                    <div className="flex flex-col gap-1">
                      <h5 className="text-sm font-medium">
                        {notification.description.title}
                      </h5>
                      <p className="text-xs text-muted-foreground line-clamp-2">
                        {notification.description.message}
                      </p>
                      <span className="text-xs text-muted-foreground mt-1">
                        {moment(notification.creationTime).format("YYYY-MM-DD")}
                      </span>
                    </div>
                  </button>
                ))}
              </div>
            ) : (
              <div className="py-8 text-center">
                <p className="text-sm text-muted-foreground">
                  No new notifications
                </p>
              </div>
            )}
          </div>

          <div className="border-t p-2 flex items-center justify-center">
            <Link
              to="/update"
              className="block w-full text-center py-2 text-sm hover:bg-muted rounded-md transition-colors "
              onClick={() => setOpen(false)}
            >
              View All
            </Link>
          </div>
        </DropdownMenuContent>
      </DropdownMenu>

      {showModal && (
        <CustomModal
          title={"View Notice"}
          show={showModal}
          onHide={() => {
            closeModal();
          }}
        >
          <UpdateView closeUpdate={closeModal} rowData={notificationData} />
        </CustomModal>
      )}
    </>
  );
};

export default Notification;

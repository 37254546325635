import Footer from "@/components/footer/footer";
import Navbar from "@/components/navbar/navbar";
import { Outlet } from "react-router-dom";

export default function SimpleLayout() {
  return (
    <div>
      <Navbar />
      <div className="min-h-[calc(100vh_-_160px)] px-4 pb-[80px]">
        <Outlet />
      </div>
      <Footer />
    </div>
  );
}

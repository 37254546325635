import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import logo from "../../assets/images/logo.png";
import { colors } from "../../constants/themeConstants";
import useCustomModal from "../../hooks/customHooks/useCusomModal";
import { selectAuthRoutesList } from "../../pages/configuration/routeSlice";
import Notification from "../../pages/update/notification";
import MaterialIcon from "../materialIcon/materialIcon";
import { NavbarWrapper, StyledSettingDropdown } from "./navbar.styled";
import UserBox from "./userBox";
import {
  useGetAllBadgeNotification,
  usePostMarkAllRead,
  usePutNotifStatusChange,
} from "../../hooks";
import useFrostSi8Api from "../../hooks/useFrostSi8Api";
import { color } from "html2canvas/dist/types/css/types/color";
import { Button } from "../ui/button";
import {
  Bell,
  ChevronDown,
  LayoutDashboard,
  Megaphone,
  MessageSquareText,
  Settings,
  SquareUserRound,
} from "lucide-react";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu";

const Navbar = () => {
  const userAuthList = useSelector(selectAuthRoutesList);
  const [openSettings, setOpenSettings] = useState(false);

  const [active, setActive] = useState<boolean>(false);
  const [notifBadge, setNotifBadge] = useState<any>();
  const handleOpen = () => {
    setActive(!active);
  };
  const { showModal, openModal, closeModal } = useCustomModal();
  const { handleRequest } = useFrostSi8Api();
  const { data: badgeNotifData } = useGetAllBadgeNotification(handleRequest);
  const { mutate } = usePostMarkAllRead(handleRequest);
  const { mutate: notifStatusChange, isLoading: notifLoading } =
    usePutNotifStatusChange(handleRequest);
  const BadgeNotif = badgeNotifData?.data;
  const markAsRead = BadgeNotif?.map((item) => {
    return item.id;
  });

  useEffect(() => {
    if (BadgeNotif) {
      const unreadNotif = BadgeNotif?.some((item) => !item.isSeen);
      setNotifBadge(unreadNotif);
    }
  }, [BadgeNotif, setNotifBadge]);

  return (
    <>
      <div className=" border  sticky min-h-[70px] top-0 bg-white flex items-center justify-between px-4 z-50">
        <Link to="/" className="flex items-center gap-4">
          <img
            src={logo}
            alt="FROST & SULLIVAN"
            className="w-[270px] sm:w-[350px] md:w-[420px] translate-y-[-2px] "
          />
        </Link>
        <div className="flex items-center gap-2">
          <div className="flex items-center gap-1">
            <Link to={"/feedback"}>
              <Button variant="ghost" className="p-2.5 md:p-3 cursor-pointer">
                <MessageSquareText className="scale-[1.3]" color="#1582C5" />
              </Button>
            </Link>
            {userAuthList?.currentUser?.roles[0] === "admin" ? (
              <DropdownMenu open={openSettings} onOpenChange={setOpenSettings}>
                <DropdownMenuTrigger>
                  <Button
                    variant="ghost"
                    className="p-2.5 md:p-3 cursor-pointer"
                  >
                    <Settings className="scale-[1.3]" color="#1582C5" />
                    <ChevronDown />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  {/* <DropdownMenuItem
                    className=" cursor-pointer !p-0"
                    onClick={() => setOpenSettings(false)}
                  >
                    <Link
                      to="/admin"
                      className="flex items-center gap-3 text-black px-2 py-2 group rounded-sm w-full"
                    >
                      <LayoutDashboard className="scale-[1.3]  transition-all" />
                      Dashboard
                    </Link>
                  </DropdownMenuItem> */}
                  <DropdownMenuItem
                    className=" cursor-pointer !p-0"
                    onClick={() => setOpenSettings(false)}
                  >
                    <Link
                      to="/user-management"
                      className="flex items-center gap-3 text-black px-2 py-2 group rounded-sm w-full"
                    >
                      <SquareUserRound className="scale-[1.3]  transition-all" />
                      User Management
                    </Link>
                  </DropdownMenuItem>
                  <DropdownMenuItem
                    className=" cursor-pointer !p-0"
                    onClick={() => setOpenSettings(false)}
                  >
                    <Link
                      to="/update"
                      className="flex items-center gap-3 text-black px-2 py-2 group rounded-sm w-full"
                    >
                      <Megaphone className="scale-[1.3]  transition-all" />
                      Update
                    </Link>
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            ) : userAuthList?.currentUser?.roles[0] ? (
              <>
                <Notification
                  markAsRead={markAsRead}
                  badgeNotif={BadgeNotif}
                  notifStatusChange={notifStatusChange}
                  mutate={mutate}
                />
              </>
            ) : (
              <></>
            )}
          </div>

          <UserBox />
        </div>
      </div>
    </>
  );
};

export default Navbar;

import { useMutation } from "react-query";
import {
  FSi8_Dtos_SI8DataRequests_Si8RequestDto,
  Si8DataRequestService,
} from "../../services/frost-si8-services";
import useGenerateDataGrowthOpportunity from "../GrowthOpportunity/PostGrowthopportunityLoader/usePostLoader";
import { useDispatch } from "react-redux";
import {
  removeSI8PoolingStatus,
  setPoolingStatus,
  setsi8RequestID,
} from "../../store/slices/SI8PoolingStatusSlice";
import { useNavigate } from "react-router-dom";

const usePostSi8DataSearchRequest = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): any => {
  const { mutate: growthDataGenerate } =
    useGenerateDataGrowthOpportunity(handleRequest);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  return useMutation(
    async (requestBody?: FSi8_Dtos_SI8DataRequests_Si8RequestDto) => {
      {
        return await handleRequest(
          Si8DataRequestService.postCoreApiApiAppSi8DataRequestSi8DataSearchRequest(
            requestBody
          )
        );
      }
    },
    {
      onSuccess: async (response, requestData) => {
        if (response?.success) {
          dispatch(setsi8RequestID(response?.data?.si8DataRequestId as string));
          if (requestData?.generateGrowthOpportunities) {
            growthDataGenerate({
              si8DataRequestId: response?.data?.si8DataRequestId,
              ...requestData?.growthInformations,
            });
          }
          if (
            response?.data?.si8DataProcessStageSystemName === "requested" ||
            response?.data?.si8DataProcessStageDisplayName === "Requested"
          ) {
            dispatch(setPoolingStatus("pooling"));
          } else if (
            response?.data?.si8DataProcessStageSystemName === "completed" ||
            response?.data?.si8DataProcessStageDisplayName === "Completed"
          ) {
            // dispatch(setPoolingStatus("completed"));
            navigate(
              `/transformation/strategic-imperative/${response?.data?.si8DataRequestId}`
            );
            dispatch(removeSI8PoolingStatus());
          }
        }
      },
      onError: async (error) => {
        console.error("Error:", error);
      },
    }
  );
};

export default usePostSi8DataSearchRequest;

/* eslint-disable no-unused-vars */
import { lazy, PropsWithChildren, Suspense } from "react";
import { Routes, Route, Navigate } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute";
import { RouteList } from "./routesList";
import { selectIsAuthenticated } from "../pages/auth/authSlice";
import AuthService from "../services/authService";
import { useSelector } from "react-redux";
import Callback from "../pages/auth/callback";
import Login from "../pages/auth/login";
import LoadingSpinner from "../components/suspenseSpinner";
import Glossary from "../pages/home/GlossaryContent";

const TransformationVisualization = lazy(
  () => import("../pages/Transformation/TransformationVisualization")
);
const TransformationSubSegment = lazy(
  () => import("../pages/Transformation/TransformationSubSegment")
);
const TransformationHome = lazy(
  () => import("../pages/Transformation/TransformationHome")
);
const TransformationTable = lazy(
  () => import("../pages/Transformation/TransformationTable")
);
const GrowthTable = lazy(() => import("../pages/growthDefaultView"));
import SuperAdminLayout from "@/layout/SuperAdminLayout";
import DashboardPage from "@/pages/admin/dashboard";
import UsermanagementPage from "@/pages/admin/user-management";
import SuperAdminRoute from "./SuperAdminRoute";
import { selectAuthRoutesList } from "@/pages/configuration/routeSlice";
import SimpleLayout from "@/layout/SimpleLayout";
import TransformationPagesLayout from "../layout/TransformationPagesLayout";
import Home from "@/pages/home/home";
import GrowthOpportunity from "../pages/growthOpportunity";
const GraftTable = lazy(() => import("@/pages/GrowthSavedReportTable"));
const ViewGrowthTable = lazy(() => import("@/pages/ViewGrowthTable"));
const GrowthHistory = lazy(() => import("@/pages/GrowthHistory"));
const DetailSavedReport = lazy(
  () => import("@/pages/GrowthSavedReportTable/DetailSavedReport")
);
const GrowthOpportunityVisualization = lazy(
  () =>
    import(
      "@/pages/GrowthOpportunityVisualization/GrowthOpportunityVisualization"
    )
);
const GrowthOpportunityDraftVisualization = lazy(
  () =>
    import(
      "@/pages/GrowthOpportunityVisualization/GrowthOpportunityDraftVisualization"
    )
);

const LazyLoadedComponent = (props: PropsWithChildren) => {
  return (
    <Suspense
      fallback={
        <>
          <LoadingSpinner />
        </>
      }
    >
      {props.children}
    </Suspense>
  );
};

const RoutesContainer = () => {
  const authService = new AuthService();
  const isAuthenticated =
    useSelector(selectIsAuthenticated) || authService.isAuthenticated();

  const userAuthList = useSelector(selectAuthRoutesList);

  return (
    <Routes>
      <Route path="/login" element={<Login />} />
      <Route path="/callback" element={<Callback />} />

      <Route
        element={
          <SuperAdminRoute
            isAuthenticated={isAuthenticated}
            user={{ role: userAuthList?.currentUser?.roles[0] }}
          />
        }
      >
        <Route path="admin" element={<SuperAdminLayout />}>
          <Route index element={<DashboardPage />} />
          <Route path="user-management" element={<UsermanagementPage />} />
        </Route>
      </Route>

      <Route element={<ProtectedRoute isAuthenticated={isAuthenticated} />}>
        <Route path="/" element={<SimpleLayout />}>
          {RouteList.map((route, i) => (
            <Route path={route.path} element={<route.component />} key={i} />
          ))}
        </Route>

        <Route path="/" element={<Home />}>
          <Route index element={<Navigate to="transformation" />} />
          {/* NEW TRANSFORMATION ROUTES  */}
          <Route path="transformation" element={<TransformationPagesLayout />}>
            <Route
              index
              element={
                <LazyLoadedComponent>
                  <TransformationHome />
                </LazyLoadedComponent>
              }
            />
            <Route
              path="strategic-imperative"
              element={
                <LazyLoadedComponent>
                  <TransformationTable />
                </LazyLoadedComponent>
              }
            />
            <Route
              path="strategic-imperative/:si8DataRequestId"
              element={
                <LazyLoadedComponent>
                  <TransformationTable />
                </LazyLoadedComponent>
              }
            />
            <Route
              path="visualization/:si8DataRequestId"
              element={
                <LazyLoadedComponent>
                  <TransformationVisualization />
                </LazyLoadedComponent>
              }
            />
            <Route
              path="sub-segment/:si8DataRequestId"
              element={
                <LazyLoadedComponent>
                  <TransformationSubSegment />
                </LazyLoadedComponent>
              }
            />
            <Route
              path="glossary"
              element={
                <LazyLoadedComponent>
                  <Glossary />
                </LazyLoadedComponent>
              }
            />
          </Route>
          {/* END OF NEW TRANSFORMATION ROUTE  */}
          <Route path="growth-opportunity" element={<GrowthOpportunity />}>
            <Route index element={<Navigate to="growth-Table" />} />
            <Route
              path="growth-Table"
              element={
                <LazyLoadedComponent>
                  <GrowthTable />
                </LazyLoadedComponent>
              }
            />
            <Route
              path="saved-report-Table"
              element={
                <LazyLoadedComponent>
                  <GraftTable />
                </LazyLoadedComponent>
              }
            />
            <Route
              path="growth-history"
              element={
                <LazyLoadedComponent>
                  <GrowthHistory />
                </LazyLoadedComponent>
              }
            />
            <Route
              path="growth-Table/:id"
              element={
                <LazyLoadedComponent>
                  <ViewGrowthTable />
                </LazyLoadedComponent>
              }
            />
            <Route
              path="saved-report-Table/:id"
              element={
                <LazyLoadedComponent>
                  <DetailSavedReport />
                </LazyLoadedComponent>
              }
            />
            <Route
              path="level-1-report/visualization/:id"
              element={
                <LazyLoadedComponent>
                  <GrowthOpportunityVisualization />
                </LazyLoadedComponent>
              }
            />
            <Route
              path="saved-report/visualization/:id"
              element={
                <LazyLoadedComponent>
                  <GrowthOpportunityDraftVisualization />
                </LazyLoadedComponent>
              }
            />
          </Route>
        </Route>
        <Route path="*" element={<Navigate to="/page-not-found" replace />} />
      </Route>
    </Routes>
  );
};

export default RoutesContainer;

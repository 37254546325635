import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

const CsInfoTooltip = ({ title }: { title: string }) => {
  return (
    <>
      <Tooltip placement="right" title={title}>
        <InfoCircleOutlined
          style={{ marginLeft: 8, fontSize: 16, cursor: "pointer" }}
        />
      </Tooltip>
    </>
  );
};

export default CsInfoTooltip;

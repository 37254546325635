import { TrendingUp } from "lucide-react";
import { Bar, BarChart, CartesianGrid, XAxis } from "recharts";

import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/components/ui/card";
import {
  type ChartConfig,
  ChartContainer,
  ChartTooltip,
  ChartTooltipContent,
} from "@/components/ui/chart";
const chartData = [
  { month: "January", desktop: 186, test: 50 },
  { month: "February", desktop: 305, test: 50 },
  { month: "March", desktop: 237, test: 50 },
  { month: "April", desktop: 73, test: 50 },
  { month: "May", desktop: 209, test: 50 },
  { month: "June", desktop: 214, test: 50 },
  { month: "January", desktop: 186, test: 50 },
  { month: "February", desktop: 305, test: 50 },
  { month: "March", desktop: 237, test: 50 },
  { month: "April", desktop: 73, test: 50 },
  { month: "May", desktop: 209, test: 50 },
  { month: "June", desktop: 214, test: 50 },
];

const chartConfig = {
  desktop: {
    label: "Desktop",
    color: "#00B0F0",
  },
  test: {
    label: "test",
    color: "#f00",
  },
} satisfies ChartConfig;

export function BarChartPreview() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>Bar Chart</CardTitle>
        <CardDescription>January - June 2024</CardDescription>
      </CardHeader>
      <CardContent>
        <ChartContainer config={chartConfig}>
          <BarChart accessibilityLayer data={chartData}>
            <CartesianGrid vertical={false} />
            <XAxis
              dataKey="month"
              tickLine={false}
              tickMargin={10}
              axisLine={false}
              tickFormatter={(value) => value.slice(0, 3)}
            />

            <ChartTooltip
              cursor={false}
              content={<ChartTooltipContent hideLabel />}
            />
            <Bar dataKey="desktop" fill="var(--color-desktop)" radius={8} />
            <Bar dataKey="test" fill="var(--color-test)" radius={8} />
          </BarChart>
        </ChartContainer>
      </CardContent>
      <CardFooter className="flex-col items-start gap-2 text-sm">
        <div className="flex gap-2 font-medium leading-none">
          Trending up by 5.2% this month <TrendingUp className="h-4 w-4" />
        </div>
        <div className="leading-none text-muted-foreground">
          Showing total visitors for the last 6 months
        </div>
      </CardFooter>
    </Card>
  );
}

import { useQuery } from "react-query";
import { GrowthOpportunitiesDataRequestService } from "../../../services/frost-si8-services";

const useGetGrowthPerspectiveList = (
  handleRequest: <T>(request: Promise<T>) => Promise<T | undefined>
): any => {
  return useQuery(
    ["getGrowthPerspectiveList"],
    async () => {
      return await handleRequest(
        GrowthOpportunitiesDataRequestService.getCoreApiApiAppGrowthOpportunitiesDataRequestGrowthPerspectives()
      );
    },
    {
      staleTime: Infinity,
    }
  );
};

export default useGetGrowthPerspectiveList;
